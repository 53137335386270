.nutritionPage {
  .RootBeforeAfterNutrition {
    height: auto;
    background-color: transparent;
    padding-bottom: 7.5vw;
    display: flex;
    justify-content: center;
    padding-top: 7.5vw;
  }

  .RootBeforeAfter-inside-nutrition {
    display: flex;
    width: 100%;
    height: 100%;
    @media (min-width: 1440px) {
      max-width: 1440px;
    }
  }

  @media (max-width: 1540px) {
    .RootBeforeAfterNutrition {
      padding-top: 15vw;
      padding-bottom: 15vw;
    }
    .RootBeforeAfter-inside-nutrition {
      max-width: 90%;
    }
  }
}
