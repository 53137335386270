.nutritionPage {
  @media (min-width: 992px) {
    .rowForMe,
    .forMeCol {
      height: 100%;
    }
  }

  .forMeCol {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .forMeDivNutrition {
    width: 100%;
    position: relative;
  }
  // .forMeDivNutrition .nutritionSectionTitle {
  //   // margin: 0;
  // }
  .forMeDivNutritionDesp {
    background-color: theme-color('nutritionBgColor');

    padding: 2vw;
    position: relative;
    top: -8vw;
  }

  .forMeCircle {
    position: relative;
  }
  .forMeCircle::before {
    @extend .textCircle;
    content: url('../../../../assets/imgs/nutrition/icons/forMeCircle.svg') !important;
  }

  .forMeDivNutrition .titleIndent {
    padding-left: 3em;
  }
  .forMeDivNutrition .nutritionSectionTitle {
    padding-top: 7vw;
  }

  .forMeColTitle {
    align-items: flex-start;
  }
  .chilliImgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .chilliImg {
    max-width: 300px;
  }

  .forMeCardNumber {
    position: absolute;
    top: -35px;

    background-color: theme-color('nutritionSecondary');
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 70px;
    height: 70px;

    margin-bottom: 0;
  }
  .forMeCardNumber .h3TitleNutrition {
    color: #fff !important;
    letter-spacing: 0 !important;
    padding: 0 !important;
  }

  .forMeDivNutrition .cardGeneralStyle {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .forMeDivNutrition .circleTextAroundContainer {
    width: fit-content;
    height: fit-content;
    bottom: auto;
    top: -200px;
  }

  .forMeDivNutrition .vegetablesContainer {
    left: 0;
    bottom: 0;
    transform: rotate(-30deg);
  }
  .forMeDivNutrition .vegetablesContainer {
    width: 20vw;
    max-width: 350px;
  }

  @media (min-width: 991px) {
    .forMeDivNutrition .cardGeneralStyle {
      margin-bottom: 25px;
      margin-top: 25px;
    }
    .forMeDivNutrition .slick-list {
      padding-top: 2vw;
    }
  }
  @media (max-width: 991px) {
    .forMeDivNutrition .titleIndent {
      padding-left: 15vw;
    }
    .forMeDivNutritionDesp {
      background: transparent;
    }
    .forMeSlider .slick-list {
      padding: 10vw 0;
    }
    .forMeSlider .slick-slide {
      padding: 3vw 0;
    }

    .forMeDivNutrition .circleTextAroundContainer {
      left: auto;
      right: 0;
      width: 40vw;
      height: 40vw;
      top: -71.5vw;
    }

    // .RootForMe-inside-nutrition .nutritionSectionTitle {
    //   margin: 0;
    //   width: 100%;
    // }

    .RootForMe-inside-nutrition .titleIndent {
      padding-left: 0;
      text-align: center;
    }

    .forMeDivNutrition .cardGeneralStyle {
      padding-left: 7vw;
      padding-right: 7vw;
      padding-top: 13vw;
      padding-bottom: 7vw;
    }
    .forMeCardNumber {
      position: absolute;
      top: -7.5vw;

      background-color: theme-color('nutritionSecondary');
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 15vw;
      height: 15vw;

      margin-bottom: 0;
    }

    .RootForMe-inside-nutrition .forMeCol {
      padding-left: 0;
      padding-right: 0;
    }
    .forMeDivNutrition .vegetablesContainer {
      left: auto;
      right: 0;
      transform: rotate(90deg);
      width: 30vw;
    }
  }
}
