// COMPONENTS
@import './nutricion/components/Cursor.scss';
//

// LOCOMOTIVE
@import './nutricion/Locomotive.scss';

// PAGES BASE STYLE
@import './nutricion/pages/old/BeforeAfter.scss';
@import './nutricion/pages/old/Donut.scss';
@import './nutricion/pages/old/Earn.scss';
@import './nutricion/pages/old/Faq.scss';
@import './nutricion/pages/old/ForMe.scss';
@import './nutricion/pages/old/Home.scss';
@import './nutricion/pages/old/Modules.scss';
@import './nutricion/pages/old/Prices.scss';
@import './nutricion/pages/old/Program.scss';
@import './nutricion/pages/old/Transformation.scss';
@import './nutricion/pages/old/Video.scss';
@import './nutricion/pages/old/Vip.scss';

@import './nutricion/sections/old/FaqAccordion.scss';
@import './nutricion/sections/old/FaqSection.scss';

// NEW
@import './nutricion/pages/Home.scss';
@import './nutricion/pages/Earn.scss';
@import './nutricion/pages/Photo.scss';
@import './nutricion/pages/ForMe.scss';
@import './nutricion/pages/Program.scss';
@import './nutricion/pages/BeforeAfter.scss';
@import './nutricion/pages/AboutMe.scss';
@import './nutricion/pages/Modules.scss';
@import './nutricion/pages/Prices.scss';
@import './nutricion/pages/GallerySlider.scss';
@import './nutricion/pages/Video.scss';

@import './nutricion/sections/HomeSection.scss';
@import './nutricion/sections/ProgramInfo.scss';
@import './nutricion/sections/EarnSection.scss';
@import './nutricion/sections/ForMeSection.scss';
@import './nutricion/sections/AboutMeSection.scss';
@import './nutricion/sections/BeforeAfterSection.scss';
@import './nutricion/sections/ModulesSection.scss';
@import './nutricion/sections/PricesSection.scss';
@import './nutricion/sections/GallerySliderSection.scss';

// FAQ
@import './nutricion/sections/FaqAccordionStyle.scss';
@import './nutricion/sections/FaqSectionStyle.scss';
@import './nutricion/pages/FaqStyle.scss';
@import './nutricion/sections/FaqAccordion.scss';
@import './nutricion/sections/FaqSection.scss';
@import './nutricion/pages/Faq.scss';

@import './nutricion/components/Navbar.scss';
@import './nutricion/components/Footer.scss';
@import './nutricion/components/loader.scss';

// THANK PAGE
@import './nutricion/thanksPage/upSell';
@import './nutricion/thanksPage/thanks';

// EXTERNAL
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
// @import '~hamburgers/_sass/hamburgers/hamburgers.scss';
@import './nutricion/components/CustomArrowsSlick.scss';

// FONTS

// NUTRITION FONTS
@font-face {
  font-family: 'Canopee';
  src: url('../fonts/nutrition/canopee/Canopee-Regular.otf') format('opentype'),
    url('../fonts/nutrition/canopee/Canopee-Regular.eot')
      format('embedded-opentype'),
    url('../fonts/nutrition/canopee/Canopee-Regular.woff2') format('woff2'),
    url('../fonts/nutrition/canopee/Canopee-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Agrandir';
  src: url('../fonts/nutrition/AGRANDIR-REGULAR.OTF') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Agrandir';
  src: url('../fonts/nutrition/AGRANDIR-NARROW.OTF') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}
@font-face {
  font-family: 'Agrandir';
  src: url('../fonts/nutrition/AGRANDIR-THINITALIC.OTF') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Agrandir';
  src: url('../fonts/nutrition/AGRANDIR-WIDEBLACKITALIC.OTF') format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Agrandir';
  src: url('../fonts/nutrition/AGRANDIR-WIDELIGHT.OTF') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');

.nutritionPage {
  .h1Title {
    font-family: 'Bruno Ace';
    font-style: normal;
    font-weight: normal;
    font-size: 74px;
    line-height: 80px;

    color: theme-color('primary');
  }

  // TIDIO TEXT NONE
  @media (min-width: 992px) {
    // #tidio-chat-iframe {
    //   width: 140px !important;
    // }
    // #tidio-chat-iframe {
    //   pointer-events: none;
    //   body {
    //     pointer-events: all;
    //   }
    // }
  }
  //

  @media (max-width: 1516px) and (min-width: 992px) {
    .h1Title {
      font-size: 4vw;
      line-height: 4vw;
    }
  }
  .h2Title {
    font-family: 'Bruno Ace';
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 70px;

    margin-bottom: 2vw;

    color: theme-color('primary');
  }
  .h3Title {
    font-family: 'Bruno Ace';
    font-style: normal;
    font-weight: normal;
    font-size: 27px;
    line-height: 30px;

    padding-top: 2vw;

    color: theme-color('primary');
  }
  .h4Title {
    font-family: 'Bruno Ace';
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;

    color: theme-color('secondary');
  }
  .h5Title {
    font-family: 'Bruno Ace';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }
  .pSubTitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 29px;
  }
  .buttonText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;

    text-align: center;
  }
  .navbarButton {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    text-align: center;
  }
  .footerTitle {
    font-family: 'Bruno Ace';
    font-style: normal;
    font-weight: normal;
    font-size: 54px;
    line-height: 50px;
    margin-bottom: 1vw;

    color: theme-color('primary');
  }
  .footerSubTitle {
    font-family: 'Bruno Ace';
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 22px;

    color: theme-color('secondary');
  }
  .pText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 30px;
  }
  .cardText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    // text-align: center;
  }

  .priceTitle {
    color: theme-color('primary');
    font-family: 'Bruno Ace';
    font-style: normal;
    font-weight: normal;
    font-size: 31px;
  }
  @media (max-width: 991px) {
    .row {
      margin-right: 0;
      margin-left: 0;
    }
  }

  @media (max-width: 991px) {
    // TEXTS
    .h1Title {
      font-size: 9vw;
      line-height: 9vw;
    }

    .h2Title {
      font-size: 7vw;
      line-height: 7.5vw;
    }

    .h3Title {
      font-size: 5vw;
      line-height: 5.5vw;
    }

    .h3TitleItalic {
      font-size: 5vw;
      line-height: 5.5vw;
    }

    .h4Title {
      font-size: 5vw;
      line-height: 5vw;
    }

    .pText {
      font-size: 3vw;
      line-height: 4vw;
    }

    .buttonText {
      font-size: 3vw;
      line-height: 3vw;
    }

    .priceTitle {
      font-size: 5vw;
    }

    .striked-price {
      font-size: 7vw;
      line-height: 7vw;
    }
    .striked-price:before {
      width: 100%;
    }

    .footerTitle {
      font-size: 10vw;
      line-height: 10vw;
    }
    .footerSubTitle {
      font-size: 5vw;
      line-height: 5vw;
    }

    // // GENERAL BUTTON SATYLE
    .generalButtonStyle {
      padding: 3vw 6vw;
    }

    // .buttonHomeContainer {
    //   padding: 2vw 40px;
    // }

    // .generalButtonStyle {
    //   width: 70%;
    //   height: auto;
    //   padding: 2vw 5vw;
    // }

    // .generalButtonStyle2 {
    //   width: 70%;
    //   height: auto;
    //   padding: 2vw 5vw;
    // }
  }

  @media (max-width: 500px) {
    // TEXTS
    .h1Title {
      // font-size: 10vw;
      // line-height: 10vw;
    }

    .h2Title {
      font-size: 7vw;
      line-height: 7.5vw;
    }

    .h3Title {
      font-size: 6vw;
      line-height: 6.5vw;
    }

    .h3TitleItalic {
      font-size: 6.5vw;
      line-height: 7vw;
    }

    .pText {
      font-size: 4vw;
      line-height: 5vw;
    }

    .buttonText {
      font-size: 4.5vw;
      line-height: 4.5vw;
    }

    // // GENERAL BUTTON SATYLE
    // .generalButtonStyle {
    //   padding: 2vw 10px;
    // }

    .buttonHomeContainer:nth-last-child(2) {
      margin-right: 0;
    }
    .buttonHomeContainer:nth-last-child(1) {
      margin-left: 0;
      margin-top: 5vw;
    }
  }
  //
  .row {
    margin-right: 0;
    margin-left: 0;
  }
  // SCROLLBAR
  .c-scrollbar {
    z-index: 99999999;
  }
  //
  a:link {
    -webkit-text-decoration: none;
    text-decoration: none;
  }

  img {
    // user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  // CUSTOM
  .bgTexture {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: theme-color('nutritionBgColor');
    background-image: url('../../assets/imgs/nutrition/background-min.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .textReveal {
    overflow: hidden;
  }
  .textReveal .h1TitleNutrition {
    // opacity: 0;
  }

  .overflowHidden {
    overflow: hidden;
  }

  .marginTopButtons {
    margin-top: 50px !important;
  }
  @media (max-width: 991px) {
    .marginTopButtons {
      margin-top: 10vw !important;
    }
  }

  .boldTextNutrition {
    font-weight: 400;
    font-style: italic;
    font-stretch: condensed;
    text-decoration: underline !important;
    text-decoration-color: theme-color('nutritionSecondary') !important;
    text-underline-offset: 0.2em;
  }

  .titleIndent {
    padding-left: 1em;
  }

  .fitContentCenter {
    width: fit-content;
    margin: 0 auto;
  }

  .textCircle {
    visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  @media (max-width: 991px) {
    path#path {
      stroke-width: 3;
    }
  }

  .nutritionSectionTitle {
    width: fit-content;
    margin: 0 auto 2vw;
    // margin-bottom: 2vw;
  }

  // TYPO

  .h1TitleNutrition {
    @extend .h1Title;
    font-family: 'Canopee';
    font-weight: 400;
    font-style: normal;

    font-size: 7vw;
    line-height: 6vw;

    color: theme-color('nutritionPrimary');
  }

  .h2TitleNutrition {
    @extend .h2Title;
    font-family: 'Canopee';
    font-weight: 400;
    font-style: normal;

    font-size: 4vw;
    line-height: 4vw;

    margin-bottom: 0;
    color: theme-color('nutritionPrimary');
  }
  .h3TitleNutrition {
    @extend .h3Title;
    font-family: 'Agrandir';
    font-weight: 400;
    font-style: normal;

    font-size: 2vw;
    line-height: 2.5vw;

    padding-bottom: 2vw;

    letter-spacing: 0.3em;

    color: theme-color('nutritionSecondary');
  }

  .faqTitleNutrition {
    @extend .h3TitleNutrition;
    font-family: 'Agrandir';
    font-size: 1.5vw !important;
    line-height: 2vw !important;

    color: theme-color('nutritionPrimary');
  }

  .cardTitleNutrition {
    @extend .h3Title;
    font-family: 'Canopee';

    padding-top: 0;
    padding-bottom: 1vw;
    letter-spacing: 0;

    color: theme-color('nutritionPrimary');
  }
  .h5TitleNutrition {
    @extend .h5Title;
  }
  .pSubTitleNutrition {
    @extend .pSubTitle;
  }
  .buttonTextNutrition {
    @extend .buttonText;
    font-family: 'Agrandir';
    font-size: 14px;
    line-height: 18px;

    color: #fff;
  }
  .navbarButtonNutrition {
    @extend .navbarButton;
  }
  .footerTitleNutrition {
    @extend .footerTitle;

    color: theme-color('nutritionPrimary');
  }
  .footerSubTitleNutrition {
    @extend .footerSubTitle;

    color: theme-color('nutritionSecondary');
  }
  .pTextNutrition {
    @extend .pText;

    font-family: 'Agrandir' !important;
    font-weight: 400;
    font-style: normal;

    font-size: 18px;
    line-height: 27px;

    color: theme-color('nutritionTextColor') !important;
  }
  .pTextNutrition a {
    color: theme-color('nutritionSecondary');
  }
  .cardTextNutrition {
    @extend .cardText;
  }
  .priceTitleNutrition {
    // @extend .priceTitle;
    font-family: 'Canopee';
    color: theme-color('nutritionPrimary');
    font-size: 5em;
    line-height: inherit;
  }
  .priceTitleTypeNutrition {
    @extend .h3TitleNutrition;
    // font-family: 'Canopee';
    color: theme-color('nutritionBgColor');
    padding-top: 0;
    padding-bottom: 0;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    font-size: 1em;
    line-height: inherit;

    text-transform: uppercase;
  }

  @media (max-width: 991px) {
    .h1TitleNutrition {
      display: flex;
      font-size: 15vw !important;
      line-height: 14vw !important;
    }
    .h2TitleNutrition {
      font-size: 12vw !important;
      line-height: 13vw !important;
    }
    .h3TitleNutrition {
      font-size: 5.5vw;
      line-height: 6vw;
    }
    .pTextNutrition {
      text-align: left !important;
      font-size: 4vw !important;
      line-height: 6vw !important;
    }

    .priceTitleNutrition {
      font-size: 20vw;
      // line-height: 5em;
    }
    .priceTitleTypeNutrition {
      font-size: 5vw !important;
    }

    .nutritionSectionTitle {
      margin-bottom: 15vw;
      width: fit-content;
    }

    .faqTitleNutrition {
      font-size: 3vw !important;
      line-height: 4vw !important;
    }
  }

  @media (max-width: 500px) {
    .faqTitleNutrition {
      font-size: 4vw !important;
      line-height: 5vw !important;
    }
  }

  // SLICK
  .slick-slide {
    position: relative;
    z-index: 1;
  }

  .slick-active {
    z-index: 2;
  }
  .slick-track {
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  @media (max-width: 991px) {
    .sliderMobileContainer {
      width: 70%;
      margin: 0 auto;
    }
  }
  @media (max-width: 750px) {
    .sliderMobileContainer {
      width: 80%;
      margin: 0 auto;
    }
  }
  @media (max-width: 400px) {
    .sliderMobileContainer {
      width: 90%;
      margin: 0 auto;
    }
  }

  @media (max-width: 991px) {
    .slick-active .slickSliderDiv {
      display: inline-block;
    }
    .slick-slide .slickSliderDiv {
      display: none;
    }
  }

  @keyframes infiniteRotation {
    to {
      transform: rotate(360deg);
    }
  }

  .vegetablesContainer {
    position: absolute;
    max-width: 250px;
    z-index: -1;
  }
  .vegetablePicture {
    transform: rotate(30deg);
  }
  .vegetableImages {
    width: 100%;
  }

  // BUTTONS
  .nutritionButtonStyle {
    position: relative;
    margin: auto;
    padding: 19px 22px;
    transition: all 0.2s ease;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      border-radius: 0 50% 50% 0;
      background: theme-color('nutritionSecondary');
      width: 30%;
      height: 100%;
      transition: all 0.3s ease;
    }
    span {
      @extend .buttonTextNutrition;
      font-weight: 400;
      letter-spacing: 0.47em;
      position: relative;
      text-transform: uppercase;
      vertical-align: middle;
    }
    &:hover {
      &::before {
        width: 100%;
        background: theme-color('nutritionSecondary');
        border-radius: 0;
      }
      svg {
        transform: translateX(0);
      }
    }
    &:active {
      transform: scale(0.96);
    }
  }
  // SECOND BUTTONS
  .nutritionSecondaryButtonStyle {
    position: relative;
    margin: auto;
    padding: 19px 22px;
    transition: color 0.2s ease, background-color 0.2s ease, border 0.2s ease;
    background-color: theme-color('nutritionPrimary');
    border: 1px solid theme-color('nutritionPrimary');
    span {
      @extend .buttonTextNutrition;
      color: theme-color('nutritionBgColor');
      font-weight: 400;
      letter-spacing: 0.47em;
      position: relative;
      text-transform: uppercase;
      vertical-align: middle;
    }
    &:hover {
      background-color: transparent;
      border: 1px solid theme-color('nutritionPrimary');
      span {
        color: theme-color('nutritionPrimary');
      }
    }
  }
  @media (min-width: 992px) {
    .nutritionSecondaryButtonStyle {
      width: fit-content;
    }
  }

  .separationMobile {
    padding-right: 1.5vw;
  }

  // EFFECTS
  // .rightEffect {
  //   // visibility: hidden;
  // }

  @media (max-width: 991px) {
    .titleIndent {
      padding-left: 20vw;
    }
    // BUTTON
    .nutritionButtonStyle,
    .nutritionSecondaryButtonStyle {
      padding: 3vw 6vw;
      span {
        font-size: 3vw;
        line-height: 18px;
        letter-spacing: 0.3em;
      }
    }
  }

  // ANIMATIONS
  @keyframes heartbeat {
    0% {
      transform: scale(0.75);
    }

    5% {
      transform: scale(1);
    }

    10% {
      transform: scale(0.75);
    }

    15% {
      transform: scale(1);
    }

    20% {
      transform: scale(0.75);
    }

    100% {
      transform: scale(0.75);
    }
  }
}
