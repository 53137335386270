.nutritionPage {
  /* BACKGROUND ROW */

  .RootModulesNutrition {
    height: auto;
    background-color: transparent;
    padding-top: 7.5vw; // TEMPORAL
    padding-bottom: 7.5vw;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 1440px) {
    .RootModulesNutrition-inside {
      max-width: 80%;
    }
  }

  @media (max-width: 991px) {
    .RootModulesNutrition {
      padding-top: 15vw;
      padding-bottom: 15vw;
    }
  }
}
