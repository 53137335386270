.oliviaPage {
  .rowSection {
    .testRow {
      width: 100%;
      padding: $sectionPaddingSpace 0;
      &:nth-child(1) {
        padding: 0;
      }
      &:nth-child(2) {
        padding-top: 0;
      }
      &:nth-child(3) {
        padding-bottom: 0;
      }
      &:nth-child(4) {
        padding-top: 0;
      }

      .testCol {
        padding: 0;
        &.testColTitle {
          text-align: left;
          padding: 0 10vw;
          p {
            margin-bottom: 3vw;
            @media (max-width: 991px) {
              margin-bottom: 6vw;
            }
          }

          // padding-left: 3vw;
          @media (max-width: 991px) {
            padding-left: auto;

            p {
              text-align: left;
            }
          }
        }
        &.testColVids {
          margin-top: 5vw;
          @media (max-width: 991px) {
            margin-top: 20vw;
          }
        }

        .sliderWrap {
          background: linear-gradient(
            to left top,
            rgba(255, 255, 255, 0.582),
            transparent
          );
          border: 1px solid theme-color('secondary');

          padding: 2vw 1vw 0 1vw;
          @media (max-width: 991px) {
            padding: 4vw 0 0 0;
          }
        }

        &.testImgsCol {
          .slides-container {
            .slide {
              picture {
                position: relative;
                width: 100%;
                height: 100%;
                &::before,
                &::after {
                  @extend h5;
                  position: absolute;
                  bottom: 0;
                }
                &::before {
                  content: 'ANTES';
                  left: 0;
                }

                &::after {
                  content: 'DESPUÉS';
                  right: 50%;
                  transform: translateX(100%);
                }
              }
              &.testImgWrapInvert {
                picture {
                  &::before {
                    content: 'DESPUÉS';
                  }
                  &::after {
                    content: 'ANTES';
                    right: 50%;
                    transform: translateX(100%);
                  }
                }
              }
              width: calc(30%);
              overflow: hidden;
              // padding-left: 2em;
              // padding-right: 2em;
              height: 100%;
              padding: 0 2vw;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                padding-bottom: 3vw;
              }
              @media (max-width: 991px) {
                width: calc(100%);
                padding: 0 6vw;
                img {
                  padding-bottom: 8vw;
                }
              }
            }
          }
        }

        &.testVidsCol {
          // margin-top: 3em;
          .slides-container {
            .slide {
              picture {
                width: 100%;
                height: 100%;
              }
              width: calc(100% / 2);
              overflow: hidden;
              // padding-left: 2em;
              // padding-right: 2em;
              height: 100%;
              padding: 0 3vw;
              &:hover {
                .playContainer {
                  background: transparent;
                  border-color: theme-color('secondary');
                }
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                // filter: grayscale(100%);z
              }
              @media (max-width: 991px) {
                width: calc(100%);
                padding: 0 3vw;
              }
            }
            @media (max-width: 991px) {
              height: 30vh;
            }
          }
          @media (max-width: 991px) {
            // margin-top: 3em;
          }
        }
      }
      @media (max-width: 991px) {
        padding: $sectionPaddingSpaceMobile 0;
        &:nth-child(1) {
          padding: 0;
        }
        &:nth-child(2) {
          padding-top: 0;
        }
        &:nth-child(3) {
          padding-bottom: 0;
        }
        &:nth-child(4) {
          padding-top: 0;
        }
      }
    }
  }
}
