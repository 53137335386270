.oliviaPage {
  button,
  a {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .buttonWrapper {
    transition: 0.3s;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    position: relative;
    // width: fit-content;
    cursor: pointer;

    &:hover {
      .buttonStyle {
      }
    }

    .buttonStyle {
      padding: 1vw 3vw;
      text-decoration: none;
      text-align: center;
      border: none;
      border-radius: 30px;
      position: relative;
      overflow: hidden;

      box-shadow: 0px 5.33333px 5.33333px rgba(0, 0, 0, 0.25);

      background-image: theme-color('primary2');
      background-size: 200% auto;

      // background-size: cover;
      background-repeat: no-repeat;
      background-position: right center;
      text-transform: uppercase;
      transition: 0.3s;
      // animation: buttonHoverEffect 1s linear infinite alternate; // ANIMATION LOOP

      &:hover {
        background-position: left center;
      }

      .buttonText {
        color: theme-color('titleColor');
      }

      &.buttonStylePaymentS {
        background-image: theme-color('titleColorGradient');
      }

      &.buttonStyleSecondary {
        background-image: none;
        background: theme-color('bgColor');

        .buttonText {
          color: theme-color('primary');
        }
      }
      @media (max-width: 991px) {
        padding: 3vw 8vw;
      }
    }
    &.buttonWrapperSecondary {
      &:hover {
        .buttonStyleSecondary {
          background: theme-color('titleColor');
          color: theme-color('bgColor');
        }
      }
    }
  }

  // @keyframes buttonHoverEffect {
  //   0% {
  //     background-position: left center;
  //   }

  //   100% {
  //     background-position: right center;
  //   }
  // }
}
