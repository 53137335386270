.nutritionPage {
  /* BACKGROUND ROW */

  .RootProgram {
    height: auto;
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding-top: 10vw;
    padding-bottom: 10vw;
  }

  /* CONTENT */
  .RootProgram-inside {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 90%;
  }

  @media (min-width: 1540px) {
    .RootProgram-inside {
      max-width: 1440px;
    }
  }
  @media (max-width: 991px) {
    .RootProgram-inside {
      max-width: 80%;
    }
  }
}
