.nutritionPage {
  .customArrows {
    background-color: red;
    width: 100px;
  }
  .slick-arrow {
    position: absolute;
    width: 30% !important;
    height: 100% !important;
    z-index: 10;

    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .slickPrev,
  .slickNext {
    position: absolute;
    z-index: 9;
    width: 80px !important;
    height: 80px !important;
    background: theme-color('nutritionSecondary') !important;
    border-radius: 50% !important;
    padding: 10px !important;
    transition: 0.3s;

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .sliderArrowMouse:hover .slickPrev,
  .sliderArrowMouse:hover .slickNext {
    background: transparent !important;
  }

  .sliderMouse {
    pointer-events: none;
  }
  .sliderMouse .slickPrev,
  .sliderMouse .slickNext {
    pointer-events: auto;
  }

  .sliderMouse .slickPrev:hover,
  .sliderMouse .slickNext:hover {
    background: transparent !important;
  }
  .slickNext {
    right: -100px !important;
  }
  .slickPrev {
    left: -100px !important;
  }

  .slickPrev svg,
  .slickNext svg {
    width: 60% !important;
    // height: 100% !important;
  }
  .slickPrev svg path,
  .slickNext svg path {
    fill: #fff;
  }
  .sliderArrowMouse:hover .slickPrev svg path,
  .sliderArrowMouse:hover .slickNext svg path {
    fill: theme-color('nutritionSecondary');
  }
  .sliderMouse .slickPrev:hover svg path,
  .sliderMouse .slickNext:hover svg path {
    fill: theme-color('nutritionSecondary');
  }

  .slick-prev:before,
  .slick-next:before {
    content: '' !important;
  }

  .slick-prev,
  .slick-next {
    @extend .cardGeneralStyle;
    margin: 0 !important;
    cursor: none !important;
    border: none !important;
  }
  // .slick-prev:hover {
  //   background: linear-gradient(90deg, rgba(255, 255, 255, 0.253), transparent) !important;
  // }
  // .slick-next:hover {
  //   background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.253)) !important;
  // }
  .slick-prev {
    left: 25px !important;
  }
  .slick-next {
    right: 25px !important;
  }

  @media (max-width: 991px) {
    .slickPrev,
    .slickNext {
      width: 10vw;
      height: 10vw;
      max-width: 10vw;
      max-height: 10vw;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9;
    }
    .slick-arrow:hover .slickPrev,
    .slick-arrow:hover .slickNext {
      border: 1px solid theme-color('nutritionSecondary');
    }
    .slickPrev:hover,
    .slickNext:hover {
      border: 1px solid theme-color('nutritionSecondary');
    }
    .slickPrev {
      left: -13vw !important;
    }
    .slickNext {
      right: -13vw !important;
    }
    .slickPrev svg,
    .slickNext svg {
      width: 100% !important;
      // height: 100% !important;
    }
    .sliderMouse {
      background: transparent !important;
    }
  }

  //   position: absolute;
  //   width: 20px;
  //   height: 20px;
  //   background-color: theme-color('nutritionSecondary');
  //   -webkit-mask: url('../imgs/nutrition/icons/leftArrow.svg') no-repeat center;
  //   mask: url('../imgs/nutrition/icons/leftArrow.svg') no-repeat center;
  .prevCursor {
    position: absolute;
    width: 5vw;
    height: 5vw;
    background-color: theme-color('nutritionSecondary');
    -webkit-mask: url('../../../imgs/nutrition/icons/leftArrow.svg') no-repeat
      center;
    mask: url('../../../imgs/nutrition/icons/leftArrow.svg') no-repeat center;
  }
  .prevCursor::before {
    content: '';
    position: absolute;
    width: 5vw;
    height: 5vw;
    border: 1px solid theme-color('nutritionSecondary');
  }

  .nextCursor {
    position: absolute;
    width: 5vw;
    height: 5vw;
    background-color: theme-color('nutritionSecondary');
    -webkit-mask: url('../../../imgs/nutrition/icons/rightArrow.svg') no-repeat
      center;
    mask: url('../../../imgs/nutrition/icons/rightArrow.svg') no-repeat center;
  }
}
