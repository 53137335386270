.rootCookies {
  background-color: theme-color('bgColor');
  //   padding: 50px;
}
.cookiesContainer {
  padding-top: 150px;
  color: theme-color('textColor');
}

.paperStyle {
  padding: 0 50px 50px 50px;
  border: 2px solid theme-color('primary');
  box-shadow: 30px 30px 0 theme-color('primary');
}
.cookiesContent h2,
.cookiesContent h3 {
  color: theme-color('textColor');
}

.cookiesContent h3 {
  margin-top: 60px;
}

@media (max-width: 700px) {
  .rootCookies {
    padding-right: 25px;
    padding-left: 25px;
  }
}
