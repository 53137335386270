.oliviaPage {
  .rowSection {
    .courseInfoCol {
      text-align: center;

      .buttonWrapper {
        margin-top: 3vw;
        text-align: center;
        @media (max-width: 991px) {
          margin-top: 8vw;
        }
      }
      @media (max-width: 991px) {
        text-align: left;
      }
    }
  }
}
