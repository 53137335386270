.nutritionPage {
  /* BACKGROUND ROW */

  .RootPricesNutrition {
    height: auto;
    background-color: transparent;
    padding-top: 7.5vw;
    padding-bottom: 7.5vw;
  }

  /* CONTENT */
  .RootPricesNutrition-inside {
    position: relative;
    margin: 0 auto;
    width: 100%;
  }

  @media (max-width: 991px) {
    .RootPricesNutrition-inside {
      width: 100%;
      padding: 0;
    }
  }

  @media (max-width: 991px) {
    .RootPricesNutrition {
      padding-top: 15vw;
      padding-bottom: 15vw;
    }
  }
}
