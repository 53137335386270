.oliviaPage {
  .rowSection {
    .forYouCol {
      display: flex;
      justify-content: center;
      align-items: center;
      & > div {
        position: relative;
      }
      &.forYouColImg {
        z-index: 2;
        @media (max-width: 991px) {
          z-index: 1;
          padding: 0;
          & > div {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            picture {
              display: flex;
              justify-content: flex-end;
            }
            .forYouImg {
              width: 90%;
            }
          }
        }
      }
      &.forYouColText {
        z-index: 1;
        h2 {
          margin-bottom: 1vw;
        }
        .forYouBg {
          z-index: -1;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          width: calc(100% + 15vw);
          @media (max-width: 1500px) {
            transform: translateY(-50%) translateX(-53%);
            width: calc(100% + 25vw);
          }
          @media (max-width: 991px) {
            transform: translateY(-50%) translateX(-50%);
            width: 100%;
          }
        }
        .contentWrap {
          margin-top: -8vw;
          padding-left: 2vw;
          .customList {
            width: 80%;
          }
          @media (max-width: 991px) {
            .customList,
            h2 {
              width: 70%;
              margin: 0 auto;
              text-align: left;
              margin-bottom: 3vw;
            }
          }
        }
        @media (max-width: 991px) {
          padding-top: 20vw;
          padding-bottom: 45vw;

          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
}
