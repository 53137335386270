.nutritionPage {
  .rowProgramNutrition {
    @media (min-width: 992px) {
      & {
        height: 100%;
      }
    }
  }
  .programContentNutrition .nutritionSectionTitle {
    width: 100%;
    margin-bottom: 0;
  }
  .programImagesContainer {
    position: relative;
  }
  .programImgOlyNutri {
    border-radius: 80px;
    overflow: hidden;
    width: 100%;
    max-width: 700px;
    box-shadow: none;
  }

  .programImgNutrition {
    position: absolute;
  }

  .programImg1 {
    left: 0;
    bottom: -4vw;
    z-index: -1;
  }

  .programImg2 {
    right: 0;
    bottom: -4vw;
  }

  .programContentNutrition {
    @media (min-width: 992px) {
      & {
        transform: rotate(20deg);
      }
    }
    transform: none;
  }
  .programColContentNutrition {
    @media (min-width: 992px) {
      & {
        transform: rotate(-20deg);
      }
    }
    transform: none;
  }

  .programContentNutrition .pTextNutrition {
    max-width: 500px;
  }

  .programColNutrition {
    @media (min-width: 992px) {
      & {
        height: 100%;
      }
    }
  }
  .programLastColNutrition {
    justify-content: flex-end;
  }
  @media (max-width: 991px) {
    .programColContentNutrition {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .programImg1 {
      width: 50%;
    }
    .programImg2 {
      width: 60%;
    }
    .programImg1 {
      left: 0;
      bottom: -15vw;
      z-index: -1;
    }

    .programImg2 {
      right: 50%;
      transform: translateX(50%);
      bottom: -15vw;
    }
  }
}
