.RootEarnCC {
  .rowSection {
    .earnCCCol {
      & > div {
        .earnCCList {
          justify-content: center;
          display: flex;
          align-items: center;

          .cardStyle {
            margin: 0.75vw;
            @media (max-width: 991px) {
              margin: 3vw;
            }
          }

          .earnCCDesc,
          .earnCCTitle {
            margin-bottom: 0;
          }

          .earnCCTitle {
            font-family: 'Russo One';
            font-weight: normal;
            font-size: 2vw;
            line-height: 2vw;
            margin-bottom: 1vw;
            @media (max-width: 991px) {
              font-size: 6vw;
              line-height: 6vw;
              margin-bottom: 2vw;
            }
          }

          span.earnCCDesc {
            padding-left: 2vw;
          }

          @media (max-width: 991px) {
            display: block;
          }

          &:nth-child(2) {
            .cardStyle {
              &:nth-child(1),
              &:nth-child(2) {
                margin-top: 0;
              }
            }
          }
          &:nth-last-child(1) {
            .cardStyle {
              &:nth-last-child(1),
              &:nth-last-child(2) {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .earnCCdivBetween {
        margin: 2vw 0;
        text-align: center;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
        @media (max-width: 991px) {
          margin: 8vw 0;
        }
      }
    }
  }
}
