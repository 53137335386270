.nutritionPage {
  .RootUpSell {
    background-color: theme-color('bgColor');
    .vslDiv {
      border-radius: 30px;
      .playContainerNutrition {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 30px;
      }
      .vslImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .upSellCol {
      .buttonWrap {
        & > svg {
          filter: invert(1);
        }
      }
      .arrowSelectText {
        &::before {
          filter: invert(1);
        }
      }
      .titleWrap {
        p {
          color: theme-color('textColor');
        }
      }

      .forYouColDiv {
        justify-content: flex-start !important;
      }
    }
  }
}
