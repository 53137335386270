.oliviaPage {
  .rowSection {
    .homeRow {
      .homeCol {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        & > div {
          width: 55%;
          padding-left: 10vw;
          // padding-right: 5vw;
          padding-bottom: 3vw;

          @media (max-width: 1550px) {
            padding-bottom: 0;
          }

          h3 {
            margin: 1vw 0 2vw 0;
          }

          .titleImg {
            width: 80%;
            height: 100%;
            @media (max-width: 991px) {
              width: 100%;
            }
          }

          .heroBg {
            width: 90%;
          }
          @media (max-width: 991px) {
            width: 80%;
            padding-left: 0;

            h3 {
              margin: 6vw 0;
            }
          }
        }
        @media (max-width: 991px) {
          padding-left: 10vw;
        }
      }
    }
  }
}
