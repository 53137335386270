/*******************************
*
* Customize your CSS here
*
********************************/
.oliviaPage {
  // @media (max-width: 991px) {
  position: relative;
  overflow: hidden;
  // }
  body {
    background: theme-color('bgColor');
  }
  .row {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    @media (max-width: 991px) {
      padding-left: 0;
      padding-right: 0;
      margin: 0;
    }
  }

  .borderLeftWrap {
    position: relative;
    // background: theme-color('primaryUp');
    // padding-left: 2px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;

      height: 100%;
      width: 2px;
      background: theme-color('primaryUp');
    }

    .borderTextContent {
      color: white;
      padding-left: 2vw;

      * {
        margin-bottom: 0;
      }

      .borderTextMarginBottom {
        margin-bottom: 1em;
      }
      @media (max-width: 991px) {
        padding-left: 4vw;
      }
    }

    &.numerationList {
      &::before {
        position: absolute;
        left: -3vw;
        top: 0;
        color: #fff;
      }
      @media (max-width: 991px) {
        &::before {
          left: -7vw;
        }
      }
    }
  }

  .customList {
    list-style: none;
    padding-left: 0;

    // width: 60%;
    li {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 1em;
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
      &::before {
        content: '';
        position: absolute;
        left: -20px;
        font-weight: bold;
        display: inline-block;
        vertical-align: middle;
        width: 3vw;
        margin-top: 10px;
        height: 3vw;
        max-width: 10px;
        max-height: 10px;
        border-radius: 50%;
        background: theme-color('titleColor');
      }
      @media (max-width: 991px) {
        position: relative;
        text-align: left;
        padding-left: 3vw;

        &::before {
          position: absolute;
          left: 0;
          // top: 50%;
          // transform: translateY(-50%);
          width: 1vw;
          height: 1vw;
        }
      }
    }
  }

  .customBulletStar {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    width: 100%;
    li {
      display: flex;
      text-align: left;
      align-items: center;
      &::before {
        content: '';
        font-weight: bold;
        display: inline-block;
        width: 100%;
        height: 1vw;
        max-width: 30px;
        max-height: 30px;

        background-image: url('../../imgs/icons/customListStar.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    @media (max-width: 991px) {
      &::before {
        width: 3.5vw;
        height: 3.5vw;
        margin-right: 2.5vw;
      }
    }
  }

  .circleArrowDown {
    position: relative;
    border: 1px solid theme-color('secondary');
    border-radius: 50%;

    width: 2.5vw;
    height: 2.5vw;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 1.5vw;

    cursor: pointer;

    svg {
      position: absolute;
      width: 50%;
      height: 50%;
      path {
        transition: stroke 0.3s;
      }
    }
    transition: background-color 0.3s;

    @media (max-width: 991px) {
      width: 8.5vw;
      height: 8.5vw;
    }
  }

  .elipse {
    position: absolute;
    border-radius: 100vw;
    width: 60vw;
    height: 60vw;
    background-image: radial-gradient(
      ellipse at center,
      #e3649098 0%,
      transparent 60%
    );
    // filter: blur(15em);
    opacity: 0.6;

    z-index: -1;

    &.elipseBlue {
      background-image: radial-gradient(
        ellipse at center,
        #bc9dfe 0%,
        transparent 60%
      );
    }

    &.elipseLeft {
      left: -20vw;
    }
    &.elipseRight {
      right: -20vw;
    }
    @media (max-width: 991px) {
      opacity: 0.5;
      width: 160vw !important;
      height: 160vw !important;
      &.elipseLeft {
        left: -50vw;
      }
      &.elipseRight {
        right: -50vw;
      }
    }
  }
}
.fslightbox-open {
  body {
    margin-right: 0 !important;
  }
}
