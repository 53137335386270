.oliviaPage {
  .rowSection {
    .moduleCol {
      &.moduleColTitle {
        text-align: center;
      }
      .moduleWrap {
        height: 100%;
      }
      .moduleCard {
        background-color: transparent;
        border: none;
        height: 100%;
        .cardHeader {
          border: none;
          background-color: transparent;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
        }
        .cardBody {
          background: theme-color('primaryGradient');

          padding: 2vw;

          display: flex;
          justify-content: center;
          align-items: center;
          p {
            margin-bottom: 0;
            color: theme-color('titleColor');
          }
          .customBulletStar {
            li {
              color: theme-color('titleColor');
              margin-bottom: 1vw;
              &:nth-last-child(1) {
                margin-bottom: 0;
              }
            }
          }
          &:nth-last-child(1) {
            margin-top: 0.5vw;
            @media (max-width: 991px) {
              margin-top: 3vw;
            }
          }

          @media (max-width: 991px) {
            padding: 4vw 6vw;
          }
        }
      }

      &.moduleColCta {
        margin-top: 5vw;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 991px) {
          margin-top: 15vw;
        }
      }

      .slick-dots {
        @media (max-width: 991px) {
          bottom: -3vw;
        }
      }
      &.moduleColSlider {
        @media (max-width: 991px) {
          padding: 0 7vw;
        }
      }
    }
  }
}
