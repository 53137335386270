/*************************************************************************
*
* Customize Bootstrap 4 with our new built-in Sass variables for 
* global style preferences for easy theming and component changes.
*
* Please read more at https://getbootstrap.com/docs/4.0/getting-started/theming/
* 
*************************************************************************/

@debug rgba(#fff, 0.8);

$theme-colors: (
  primary: #e36491,
  primaryGradient: linear-gradient(to top left, #b3354a 0%, #e36491 90%),
  primary2: linear-gradient(to right, #fa6ba4, #c7254c),
  secondary: #ffffff,
  secondaryGradient: linear-gradient(to top left, #fb135918 30%, #bc9dfe 130%),
  bgColor: #ffffff,
  cards: linear-gradient(to bottom, #e2daf8, #f4e7fd),
  titleColor: #ffffff,
  titleColorGradient: linear-gradient(to right, #c381db 0%, #4e92f9 40%),
  playBtn: #694ed6,
  textColor: #000000,
  // NUTRITION
  nutritionPrimary: #e8e5d4,
  nutritionSecondary: #f45539,
  nutritionBgColor: #1b4223,
  nutritionTextColor: #e8e5d4,
);
