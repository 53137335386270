.oliviaPage {
  .rowSection {
    .transformCol {
      display: flex;
      justify-content: center;
      align-items: center;
      &.transformColText {
        text-align: center;
        @media (max-width: 991px) {
          text-align: left;
        }
      }

      &.transformColImg {
        margin: 3vw 0;

        & > div {
          width: 80%;

          .transformCard {
            &:nth-child(1) {
              transform: rotate(-9.95deg);
              bottom: -2vw;
            }
            &:nth-last-child(1) {
              transform: rotate(8.44deg);

              @media (max-width: 991px) {
                margin-top: -5vw;
              }
            }
          }
        }
        .beforeAfterText {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }
        img {
          height: 100%;
          object-fit: cover;
        }
        @media (max-width: 991px) {
          margin: 6vw 0;
        }
      }
    }
  }
}
