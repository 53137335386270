.nutritionPage {
  /* BACKGROUND ROW */

  .RootEarn {
    height: auto;
    background-color: transparent;
    padding-top: 7.5vw;
    padding-bottom: 7.5vw;

    display: flex;
    justify-content: center;
  }

  /* CONTENT */
  .RootEarn-inside {
    display: flex;
    width: 100%;
    height: 100%;
    // max-width: 90%;
  }

  @media (min-width: 1440px) {
    .RootEarn-inside {
      max-width: 1440px;
    }
  }

  @media (max-width: 991px) {
    .RootEarn {
      padding-top: 20vw;
      padding-bottom: 10vw;
    }
  }
}
