.RootTestUs {
  padding-bottom: 0 !important;
  .rowSection {
    max-width: 100%;
    .testCol {
      &.testUsImgsCol {
        .slides-container {
          .slide {
            picture {
              &::before {
                content: '' !important;
              }
              &::after {
                content: '' !important;
              }
            }
            img {
              padding-bottom: 0 !important;
            }
          }
        }
      }
    }
  }
}
