.oliviaPage {
  .rowSection {
    .paymentCol {
      display: flex;
      justify-content: center;
      align-items: center;
      .paymentCardDeckLatam {
        width: 100%;
        display: flex;
        justify-content: center;
        .cardStyle {
          @media (max-width: 991px) {
            flex: none;
          }
        }
      }
      .paymentCardDeck {
        justify-content: center;
        @media (max-width: 991px) {
          width: 100%;
        }
      }
      .cardStyle {
        padding: 1vw;
        // margin: auto;
        width: fit-content;

        display: flex;
        justify-content: center;
        align-items: center;
        // width: 30vw;

        @media (min-width: 992px) {
          flex: none;
        }
        .cardHeader,
        .cardFooter {
          background: transparent;
          border: none;
        }
        .cardHeader {
          margin-bottom: 2vw;
          @media (max-width: 991px) {
            margin-bottom: 5vw;
          }
        }
        .cardFooter {
          margin-top: 2vw;
        }
        .cardBody {
          .cardBodyDiv {
            position: relative;
            text-align: center;
            display: block;
            .after {
              margin-top: 2vw;
            }
            span {
              display: block;
            }
          }
        }

        .cardWrap {
          padding: 4vw 8vw;
          background: theme-color('primaryGradient');
          @media (max-width: 991px) {
            padding: 12vw 0;
            width: 100%;
          }
        }

        &:nth-child(1) {
          .cardBody,
          .cardHeader {
            * {
              color: theme-color('titleColor');
            }
          }
        }
        &:nth-last-child(1) {
          .cardBody,
          .cardHeader {
            * {
              color: theme-color('playBtn');
            }
          }
          .cardWrap {
            background: theme-color('secondaryGradient');
          }
        }
        .cardCtaWrap {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;

          width: 100%;

          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 991px) {
            bottom: -3vw;
          }
        }
        @media (max-width: 991px) {
          width: 100%;
          padding: 3vw;
          &:nth-child(1) {
            margin-bottom: 15vw;
          }
        }
      }
    }
  }
}
