.nutritionPage {
  .RootProgramNutrition {
    height: auto;
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding-bottom: 10vw;
    padding-top: 7.5vw !important;
  }
  .RootProgram-inside-nutrition {
    display: flex;
    width: 100%;
    height: 100%;

    max-width: 80% !important;
  }
  @media (max-width: 991px) {
    .RootProgramNutrition {
      padding-top: 15vw;
      padding-bottom: 15vw;
    }

    .RootProgram-inside-nutrition {
      max-width: 100%;
    }
  }
}
