.nutritionPage {
  .aboutMeColNutrition {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (min-width: 992px) {
      height: 100%;
    }
  }

  .aboutMeColNutrition:nth-child(1) {
    right: 0 !important;
  }

  .aboutMeColNutrition:nth-last-child(1) {
    left: 0 !important;
  }
  .aboutMeContentNutrition .nutritionSectionTitle {
    margin: 0;
  }

  .aboutMeContentNutrition .forMeCircle::before {
    content: url('../../../../assets/imgs/nutrition/icons/aboutMeCircle.svg') !important;

    display: flex;
    align-items: center;
  }

  .aboutMeDivNutrition {
    width: 80%;

    @media (max-width: 991px) {
      width: 100%;
      .programDiv {
        width: 100%;
      }
      .programDiv .pText {
        margin-top: 8vw;
        margin-bottom: 8vw;
      }
    }
  }

  .aboutMeImgNutrition {
    overflow: hidden;

    border-radius: 0;
    width: 100%;

    //   @media (max-width: 991px) {
    //     .aboutMeImg {
    //       border-radius: 80px;
    //       overflow: hidden;
    //       width: 100%;
    //     }
    //   }
  }

  .aboutMeImgNutrition2 {
    position: absolute;
    width: 15vw;
    right: 0;
    bottom: -5vw;
  }

  .aboutMeImagesContainerNutrition {
    position: relative;
  }

  @media (max-width: 991px) {
    .AboutMeColContentNutrition {
      padding-left: 0;
      padding-right: 0;
    }
    .aboutMeContentNutrition .nutritionSectionTitle {
      margin: 0 auto;
    }

    .aboutMeContentNutrition .pTextNutrition {
      width: 80%;
      margin: 15vw auto 0 auto;
    }
    .aboutMeImgNutrition {
      width: 90%;
    }
    .aboutMeImgMobile {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .aboutMeImgNutrition2 {
      position: absolute;
      width: 45vw;
      right: 0;
      bottom: -12vw;
    }
    .aboutMeContentNutrition .nutritionSectionTitle {
      margin-bottom: 10vw;
    }

    .aboutMeContentNutrition .h3TitleNutrition {
      width: 80%;
      margin: 0 auto;
    }
  }
}
