.nutritionPage {
  #loader-wrapper {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999999999;
  }
  #loader {
    display: block;
    position: relative;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

    z-index: 1001;
  }

  @media (max-width: 991px) {
    #loader svg {
      width: 70%;
      max-width: 250px;
    }
  }

  //     #loader:before {
  //         content: "";
  //         position: absolute;
  //         top: 5px;
  //         left: 5px;
  //         right: 5px;
  //         bottom: 5px;
  //         border-radius: 50%;
  //         border: 3px solid transparent;
  //         border-top-color: #e74c3c;

  //         -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  //         animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  //     }

  //     #loader:after {
  //         content: "";
  //         position: absolute;
  //         top: 15px;
  //         left: 15px;
  //         right: 15px;
  //         bottom: 15px;
  //         border-radius: 50%;
  //         border: 3px solid transparent;
  //         border-top-color: #f9c922;

  //         -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  //           animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  //     }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(0deg); /* IE 9 */
      transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
    }
    100% {
      -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(360deg); /* IE 9 */
      transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(0deg); /* IE 9 */
      transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
    }
    100% {
      -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(360deg); /* IE 9 */
      transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
    }
  }

  .nutritionLayoutContainer {
    #loader-wrapper {
      cursor: none;
    }
    #loader-wrapper .loader-section {
      background: theme-color('nutritionBgColor');
    }
  }
  .layoutMain {
    #loader-wrapper .loader-section {
      background: #fff;
    }
    .mainImgLoader {
      width: 50%;
    }
    #loader picture {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  #loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    -webkit-transform: translateX(0); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(0); /* IE 9 */
    transform: translateX(0); /* Firefox 16+, IE 10+, Opera */
  }

  #loader-wrapper .loader-section.section-right {
    right: 0;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

/* Loaded */
.loadedNutri #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(100%); /* IE 9 */
  transform: translateX(100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);

  -webkit-animation: 1s ease 0s normal forwards 1 fadeOut;
  animation: 1s ease 0s normal forwards 1 fadeOut;
}

.loadedNutri #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.loadedNutri #loader-wrapper {
  visibility: hidden;

  -webkit-transform: translateY(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(-100%); /* IE 9 */
  transform: translateY(-100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}
