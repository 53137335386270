.nutritionPage {
  /* BACKGROUND ROW */

  .RootVip {
    height: auto;
    background-color: transparent;
    margin-top: 100px;
    display: flex;
    justify-content: center;
  }

  /* CONTENT */
  .RootVip-inside {
    display: flex;
    width: 100%;
    height: 100%;
    // max-width: 90%;
  }

  @media (min-width: 1440px) {
    .RootVip-inside {
      max-width: 1440px;
    }
  }

  @media (max-width: 991px) {
    .RootVip {
      padding-top: 20vw;
      padding-bottom: 10vw;
    }
    .RootVip-inside {
      max-width: 80%;
    }
    #_form_152_submit {
      width: 100% !important;
    }
  }
  @media (max-width: 600px) {
    .RootVip-inside {
      max-width: 90%;
    }
  }
}
