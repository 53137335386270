.oliviaPage {
  .RootTest {
    position: relative;
    margin-top: -25vw;
    z-index: 1;
    .elipseBlue {
      width: 80vw;
      height: 80vw;
    }
    .rowSection {
      z-index: 2;
      position: relative;
      max-width: 100%;

      .sphereD {
        top: -15vw;
        @media (max-width: 991px) {
          right: 10vw;
          top: -45vw;
          left: auto;
        }
      }
    }
    @media (max-width: 991px) {
      margin-top: -15vw;
    }
  }
}
