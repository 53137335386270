.nutritionPage {
  /* BACKGROUND ROW */

  .RootFaqNutrition {
    height: auto;
    background-color: transparent;
    margin-top: 100px;
    display: flex;
    justify-content: center;
  }

  /* CONTENT */
  .RootFaqNutrition-inside {
    display: flex;
    width: 100%;
    height: 100%;
    // max-width: 90%;
  }

  @media (min-width: 1440px) {
    .RootFaqNutrition-inside {
      max-width: 1440px;
    }
  }
  @media (min-width: 992px) and (max-width: 1560px) {
    .RootFaqNutrition-inside {
      max-width: 80%;
    }
  }
  @media (max-width: 991px) {
    .RootFaqNutrition-inside {
      max-width: 90%;
    }
  }
}
