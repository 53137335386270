.nutritionPage {
  .nutritionPage {
    @media (min-width: 992px) {
      .rowFaq,
      .faqCol {
        height: 100%;
      }
    }

    .faqDiv {
      display: flex;
      margin-bottom: 5vw;
    }
    .faqDivText {
      padding-left: 50px;
    }
    .customBullet {
      position: absolute;
      left: 0;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: theme-color('primary');
      box-shadow: inset 3px 4px 11px rgba(248, 248, 248, 0.42);
    }

    //   TEXT
    .faqTitle {
      font-family: 'Bruno Ace';
      font-style: normal;
      font-weight: normal;
      font-size: 31px;
      line-height: 35px;
    }

    .faqText {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 25px;
    }

    @media (max-width: 991px) {
      .faqDiv {
        justify-content: center;
      }
    }
  }
}
