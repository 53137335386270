.oliviaPage {
  .RootModule {
    z-index: 2;
    .elipse {
      width: 80vw;
      height: 80vw;
      // top: -20vw;
      z-index: 2;
    }
    .rowSection {
      z-index: 3;
      // max-width: 100%;
    }
  }
}
