.oliviaPage {
  //  CARDS
  $grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
  ) !default;

  $grid-gutter-width: 30px;
  $grid-gutter-width-payment: 100px;

  $cards-per-line: (
    xs: 1,
    sm: 1,
    md: 2,
    lg: 2,
    xl: 2,
  );
  $cards-per-line-3: (
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 3,
  );

  @each $name, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      .card-deck .paymentCard {
        flex: 0 0
          calc(
            #{100 / map-get($cards-per-line, $name)}% - #{$grid-gutter-width-payment}
          );
      }
      .card-deck .earnCCCard {
        flex: 1 1
          calc(
            #{100 / map-get($cards-per-line, $name)}% - #{$grid-gutter-width-payment}
          );
      }
      .card-deck .earnCard {
        flex: 0 0
          calc(
            #{100 / map-get($cards-per-line-3, $name)}% - #{$grid-gutter-width}
          );
      }
    }
  }

  .cardStyle {
    background: theme-color('cards');
    border: none;
    padding: 2vw;
    .card-body {
      position: relative;
    }
    .cardHeader,
    .cardBody {
      display: flex;
      justify-content: center;
      align-items: center;

      text-align: center;
      padding: 0;

      @media (max-width: 991px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .cardBody {
      h4 {
        font-family: 'Akkordeon fourteen';
        font-style: normal;
        font-weight: 900;
        font-size: 3.5vw;
        line-height: 3vw;

        @media (max-width: 991px) {
          font-size: 10.5vw;
          line-height: 10vw;
        }
      }
    }
    @media (max-width: 991px) {
      padding: 6vw;
    }
  }
}
