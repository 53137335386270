.nutritionPage {
  .RootFooterNutrition {
    // background-color: theme-color("primary");
    height: auto;
    background-color: transparent;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 100px 0 100px 0 !important;
  }

  .RootFooter-inside-nutrition {
    display: flex;
    z-index: 99;

    width: 100% !important;
    max-width: 90% !important;
  }
  .footerCol {
    display: flex;
    justify-content: center;
    align-items: center;

    .listGroupFooter {
      width: fit-content;
      // @media (max-width: 991px) {
      //   width: 60%;
      // }
    }

    .footerColWrapper {
      // width: 100%;
      .footerColDiv {
        display: flex;
        align-items: center;
        // justify-content: center;

        &:nth-child(1) {
          padding-left: 3em;
        }
        @media (max-width: 991px) {
          justify-content: center;
          &:nth-child(1) {
            padding-left: 0;
          }
        }
        // &:nth-child(2) {
        //   justify-content: flex-start;
        // }

        .footerSubTitle .textStroke {
          -webkit-text-stroke: 1px theme-color('nutritionPrimary');
          // color: theme-color('primary');
        }
      }
    }
    .footerFaceText {
      margin-top: 3vw;
      color: theme-color('nutritionPrimary');
      font-size: 13px;
      @media (max-width: 1500px) and (min-width: 992px) {
        font-size: 1vw;
      }
      @media (max-width: 991px) {
        margin-top: 15vw;

        font-size: 3vw;
      }
    }
    @media (max-width: 991px) {
      text-align: left;
    }
  }

  .listGroupItemFooterNutrition {
    background-color: transparent;
    border: none;
    padding: 0;
    padding-bottom: 0.3em;
    margin-bottom: 1.6em;
  }
  .listGroupItemFooterNutrition a {
    color: theme-color('nutritionPrimary') !important;
  }
  .listGroupItemFooterNutrition a:hover {
    color: theme-color('nutritionSecondary') !important;
    opacity: 0.8;
    cursor: none;
  }

  .listGroupItemFooterNutrition {
    margin-bottom: 0.5em !important;
  }

  .poweredSectionNutrition {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: theme-color('nutritionSecondary');
    }
  }

  .pTextPoweredNutrition {
    font-family: 'Agrandir';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.47em;
    a {
      color: theme-color('nutritionSecondary') !important;
    }
  }
  .interestLinks {
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
  }

  .brandFooterNutrition {
    @extend .brandFooter;
    @media (max-width: 991px) {
      margin-bottom: 50px;
    }
  }

  @media (max-width: 991px) {
    .RootFooterNutrition {
      padding: 30vw 0 100px 0 !important;
    }

    .footerBrandNutrition {
      width: 100%;
    }
  }
}
