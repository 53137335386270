.RootThanks {
  padding-top: 10vw;
  padding-bottom: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .rowSection {
    text-align: center;
    .thanksCol {
      display: flex;
      justify-content: center;
      align-items: center;

      .thanskWrap {
        .thanksTitle {
          text-align: center;
          h2 {
            margin-bottom: 0;
          }
        }
        .thanksButton {
          margin: 3vw 0;
          @media (max-width: 991px) {
            margin: 10vw 0;
          }
        }
        .thanksQuest {
          img {
            width: 5vw;
            @media (max-width: 991px) {
              width: 10vw;
            }
          }
          h5 {
            margin: 1em 0;
          }
          a {
            color: theme-color('buttonsColor');
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    padding-bottom: 10vw;
  }
}
