.nutritionPage {
  /* BACKGROUND ROW */

  .RootVideo {
    height: auto;
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 5vw;
  }

  /* CONTENT */
  .RootVideo-inside {
    // display: flex;
    width: 80%;
    height: 100%;
    max-width: 1440px;
  }

  .responsiveEmbedDiv {
    // width: 100%;
    // height: 720px;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .playContainer {
    // width: 100px;
    // height: 60px;
    background-color: rgba(0, 0, 0, 0.473);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .responsiveEmbedDiv:hover {
    cursor: pointer;
  }
  .responsiveEmbedDiv:hover .playIcon {
    color: theme-color('primary');
  }

  .playIcon {
    font-size: 2em;
    color: #fff;
  }

  .plyr--vimeo {
    // border-radius: 80px;
  }

  .modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .modal-dialog {
    width: 100%;
    max-width: 60vw;
  }
  .modal-content {
    background: transparent;
    border: none;
  }

  .embed-responsive {
    border-radius: 80px;
  }

  .candyDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-10vw) rotate(26deg);
    pointer-events: none;
  }
  .videoCandy {
    position: relative;
    left: 30%;
    width: 100px;
  }

  @media (max-height: 700px) and (min-width: 992px) {
    .RootVideo {
      padding-top: 15vw;
    }
  }
  @media (max-height: 600px) and (min-width: 992px) {
    .RootVideo {
      padding-top: 20vw;
    }
  }
  @media (max-height: 500px) and (min-width: 992px) {
    .RootVideo {
      padding-top: 25vw;
    }
  }

  @media (min-width: 1440px) {
    .RootVideo-inside {
      max-width: 1440px;
    }
  }
  @media (min-width: 992px) {
    .RootVideo-inside {
      max-width: 60%;
    }
  }

  .RootVideo-inside {
    width: 90%;
  }
  @media (max-width: 991px) {
    .embed-responsive {
      border-radius: 30px;
    }
    .videoCandy {
      width: 70%;
    }
  }
  @media (max-width: 600px) {
    .videoCandy {
      width: 50%;
    }
  }
}
