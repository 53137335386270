.oliviaPage {
  .navContent {
    margin-right: 10%;
    transition: 0.3s;
  }

  .navImgBg {
    position: absolute;
    left: 0;
    top: 0;
    width: 85%;
    height: 100%;
    z-index: -1;

    @media (max-width: 991px) {
      right: -15vw;
      top: -15vw;
      left: auto;
      width: 40vw;
      height: 40vw;

      background-color: theme-color('primary');

      border-radius: 50vw;

      // transition: transform 1s;
      transform-origin: top right;
    }
  }

  .navWrap {
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;

    .linksNav {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0 1vw;

      .buttonStyle {
        padding: 0.5vw 3vw;

        box-shadow: none;
        border: 1px solid rgba(255, 255, 255, 0.486);
        @media (max-width: 991px) {
          padding: 3vw 6vw;
        }
      }

      &.linksNavSmooth,
      .linksNavSmooth {
        @media (min-width: 992px) {
          transition: opacity 0.3s;
        }
        &:hover {
          opacity: 0.6;
        }
      }

      .socialLinks {
        margin: 0 0.5vw;
      }
      @media (max-width: 991px) {
        padding: 3vw 0;
      }
    }
    @media (max-width: 991px) {
      display: block;
    }
  }

  #navbar {
    transform: translateY(0);
    align-items: flex-start;
    &.hideNavbar {
      transform: translateY(-100%);
    }

    &.nav-promo {
      margin-top: 100px !important;
      @media (max-width: 991px) and (min-width: 500px) {
        margin-top: 100px !important;
      }
      @media (max-width: 499px) {
        margin-top: 23vw !important;
      }
    }
  }

  // on focus
  // .navbar-light .navbar-nav .show > .nav-item,
  // .navbar-light .navbar-nav .active > .nav-item,
  // .navbar-light .navbar-nav .nav-item.show,
  // .navbar-light .navbar-nav .nav-item.active {
  //   color: inherit;
  // }
  // .navbar-light .navbar-nav .nav-item:hover,
  // .navbar-light .navbar-nav .nav-item:focus {
  //   color: inherit;
  // }
  //
  .navbarBrand {
    color: #fff !important;

    margin-right: 0;
  }

  // .navbar-light .navbar-nav .nav-item {
  //   // color: theme-color('textColor') !important;
  // }

  .fixed-top {
    top: 0;
    height: 160px;
  }

  #navbar {
    transform: translate3d(0, 0, 0);
    transition: transform 0.5s ease-in-out;
  }

  // .navCollapse {
  //   display: flex;
  //   justify-content: space-between;
  // }

  .navbar-light .navbar-nav .nav-item {
    // margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    // transition: 0.3s;
  }

  .navbar-light .navbar-nav .nav-item {
  }

  // .navbar-light .navbar-nav .nav-item:hover {
  //   // color: theme-color('primary') !important;
  // }

  .nav-color-scroll {
    background-color: transparent;
    padding-right: 5vw;
    padding-left: 5vw;

    @media (max-width: 991px) {
      // padding-right: 10vw;
      // padding-left: 10vw;
    }
  }

  #navbar {
    // transition: top 0.3s, 0.5s;
  }

  .navbar--hidden {
    top: -50px;
  }

  .navbar-collapse {
    // position: absolute;
    right: 86px;
  }

  //TOGGLER
  .navbar-toggler {
    border: none;
    font-size: 2em;
  }

  /* RESPONSIVE STYLE */
  @media (max-width: 991px) {
    #navbar {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .navWarpAnim {
      position: absolute;
      height: auto;
      right: 0;
      left: auto;
      width: 100%;
      // max-width: 40%;
      transition: transform 1s;
      transform-origin: top right;
      background-color: theme-color('primary');
    }
    .navWrap {
      height: auto;
      pointer-events: none;
      &.navWrapEvents {
        pointer-events: all !important;
      }
    }
    .navExpanded {
      &#navbar {
        height: 100vh;
      }
    }
    .navToggle {
      float: right;
    }
    .navCollapse {
      background-color: transparent !important;
      // height: 100vh;
    }
  }

  @media (max-width: 991px) {
    .navbar-brand {
      img {
        width: auto;
        height: 100%;
        @media (max-width: 991px) {
          width: 40vw;
        }
      }
    }

    #containerNavToggle {
      // height: 100% !important;
    }

    .nav-color {
      background: rgba(0, 0, 0, 0.747);
    }

    // .fixed-top {
    //    height: 70px;
    // }

    .navbar-collapse {
      position: initial;
      right: 0;
    }

    .custom-toggler .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.8)' stroke-width='4' stroke-linecap='butt' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }

    .navbar-toggler {
      margin-right: 20px;
    }

    .navbar-collapse {
      background-color: #fff;
    }
  }
  // NAV BURGER
  .navBurger {
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // transform: rotate(90deg);
    z-index: 10;
    width: 100%;
    height: 100%;
    @media (max-width: 991px) {
      max-width: none;
      top: 0;
      width: 100%;
      height: 72px;
      justify-content: center;
      align-items: flex-end;
      // padding-top: 5vw;
      // padding-right: 5vw;

      &.navBurgerOlivia {
        align-items: flex-start;
        img {
          margin-left: 10px;
          width: 50vw;
          max-height: 90%;
          object-fit: contain;
          object-position: left;
        }
      }
    }
  }

  // HAMBURGER BUTTON
  .hamburgerButton {
    position: absolute;
    top: 8vw;
    right: 5vw;
  }
  .toggle-btn {
    width: 40px;
    height: 12px;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    @media (max-width: 991px) {
      width: 10vw;
    }
  }
  span.hambOne,
  span.hambTwo {
    position: absolute;
    width: 40px;
    height: 2px;
    background: theme-color('secondary');
    @media (max-width: 991px) {
      width: 10vw;
    }
  }

  span.hambTwo {
    margin-top: 12px;
  }

  .navbar-nav .buttonStyle span {
    color: theme-color('primary');
  }
}
