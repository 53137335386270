.ver-ano {
  width: 30vw !important;
  margin: 2vw auto;
  height: auto;

  @media (max-width: 991px) {
    width: 70vw !important;
    margin: 6vw auto;
  }
}
