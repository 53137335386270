.nutritionPage {
  // TINY SLIDER

  .tns-controls {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    left: 0;
    background: #333;
    width: 100%;
    top: 50%;
    transform: translatey(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:focus {
      outline: none;
    }

    li:hover {
      cursor: pointer;
    }
  }

  //
  .cardGeneralStyle {
    background: transparent;
    border: 1px solid theme-color('nutritionSecondary');
    border-radius: 0px 25px 0px 0px;
    max-width: 800px;
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .cardGeneralStyle {
    padding: 2.5rem;
  }
  .cardGeneralStyle .card-body {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .earnContainer .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1vw;
    padding-bottom: 1vw;
  }

  .earnContainerNutrition {
    @extend .earnContainer;
    max-width: none;
  }
  .earnContainerNutrition .nutritionSectionTitle {
    width: fit-content;
    margin: 0 auto 2vw;
    position: relative;
  }

  .earnContainerNutrition .h3TitleNutrition {
    padding-top: 0;
  }
  .earnContainerNutrition .vegetablesContainer {
    left: -10vw;
    top: -5vw;
    transform: rotate(-30deg);
  }

  .earnContainerNutrition .nutritionSecondaryButtonStyle {
    background-color: theme-color('nutritionSecondary');
    border: 1px solid theme-color('nutritionSecondary');
    span {
      color: theme-color('nutritionPrimary');
    }
    &:hover {
      background-color: transparent;
      border: 1px solid theme-color('nutritionSecondary');
      span {
        color: theme-color('nutritionPrimary');
      }
    }
  }

  @media (max-width: 991px) {
    .cardGeneralStyle {
      padding: 7vw;
    }
    .earnContainerNutrition {
      padding-left: 0;
      padding-right: 0;
    }
    .earnContainerNutrition .vegetablesContainer {
      width: 50vw;
      left: auto;
      right: -10vw;
      top: -40vw;
    }
  }
}
