.oliviaPage {
  .container-fluid {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .layoutContainer {
    // width: 100vw;
    // height: 100vh;
    margin: 0 auto;
  }
}
