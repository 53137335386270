.nutritionPage {
  @media (min-width: 992px) {
    .rowAboutMe {
      height: 100%;
    }
  }

  .RooTAboutMeNutrition {
    height: auto;
    background-color: transparent;
    padding-top: 7.5vw;
    padding-bottom: 7.5vw; // TEMPORAL
    display: flex;
    justify-content: center;
  }
  .RootAboutMe-inside-nutrition {
    display: flex;
    width: 100%;
    height: 100%;
    // max-width: 90%;

    @media (min-width: 1440px) {
      & {
        max-width: 1440px;
      }
    }
  }
  @media (max-width: 1540px) {
    .RootAboutMe-inside-nutrition {
      max-width: 90%;
    }
  }
  @media (max-width: 991px) {
    .RooTAboutMeNutrition {
      padding-top: 15vw;
      padding-bottom: 15vw;
    }
    .RootAboutMe-inside-nutrition {
      max-width: 100%;
    }
  }
}
