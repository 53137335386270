.nutritionPage {
  /* BACKGROUND ROW */

  .RootModules {
    height: auto;
    background-color: transparent;
    padding-bottom: 7.5vw;
    display: flex;
    justify-content: center;
  }

  /* CONTENT */
  .RootModules-inside {
    display: flex;
    width: 100%;
    height: 100%;
    // max-width: 90%;
  }

  @media (min-width: 1440px) {
    .RootModules-inside {
      max-width: 80%;
    }
  }
  @media (max-width: 991px) {
    .RootModules {
      padding-top: 10vw;
      padding-bottom: 10vw;
    }
  }
}
