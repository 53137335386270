.nutritionPage {
  .faqColNutrition {
    height: auto !important;
    @extend .faqCol;
    .slick-prev {
      left: 0 !important;
    }
    .slick-next {
      right: 0 !important;
    }
  }
  .courseContentIconNutrition {
    @extend .courseContentIcon;

    background-color: transparent;
    border: 1px solid theme-color('nutritionSecondary');

    box-shadow: none;
    left: -80px;

    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 100%;
    }
  }

  .faqSectionRowNutrition {
    @extend .faqSectionRow;
  }
  .faqTitleNutrition:hover .courseContentIconNutrition {
    border: none;
  }

  .faqCardsAccordionNutrition {
    @extend .faqCardsAccordion;
    border-bottom: 1px solid theme-color('nutritionPrimary') !important;
  }

  .faqColNutrition .nutritionSectionTitle {
    width: 100%;
  }
  .cardFaqContentNutrition .card {
    margin-left: 70px;
    padding-left: 0;
    margin-top: 50px;
    @media (max-width: 991px) {
      margin-top: 30px;
    }
  }
  .cardFaqContentNutrition {
    @extend .cardFaqContent;
  }
  .cardFaqContentNutrition .card-header {
    @extend .faqTitleNutrition;
  }
  .cardFaqContentNutrition .card-body {
    @extend .pTextNutrition;
  }

  .faqColNutrition .slick-slide {
    padding: 0 2vw;
  }
  .faqColNutrition .slick-list {
    padding: 0 -2vw;
  }

  // IMG

  .CucumberImgContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .vegetablesContainer {
      right: 0;
    }
  }

  @media (max-width: 991px) {
    .courseContentIconNutrition {
      padding: 2vw 3vw;
      width: 15vw;
      height: 15vw;
      left: -20vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .courseContentIconNutrition svg {
      height: 100%;
    }
    .faqTitleNutrition:hover .courseContentIconNutrition {
      background-color: transparent;
      border: 1px solid #f45539;
    }
    .faqSectionRowNutrition {
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 100%;
    }

    .cardFaqContentNutrition .card {
      margin-left: 20vw;
      padding-left: 0;
    }
    .faqColNutrition .nutritionSectionTitle {
      width: fit-content;
    }
    .faqAccFirstColNutrition {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .CucumberImgContainer {
      .vegetablesContainer {
        top: -10vw;
        max-width: 40%;
      }
      .vegetableImages {
        // width: 50%;
      }
    }

    .cardFaqContentNutrition .card-header,
    .faqTitleNutrition {
      font-size: 4vw !important;
      line-height: 5vw !important;
    }
  }
}
