.nutritionPage {
  /* BACKGROUND ROW */

  .RootVideoNutrition {
    height: auto;
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 5vw;
  }

  /* CONTENT */
  .RootVideo-inside {
    // display: flex;
    width: 80%;
    height: 100%;
    max-width: 1440px;
  }

  .RootVideoNutrition .nutritionSecondaryButtonStyle {
    background-color: theme-color('nutritionSecondary');
    border: 1px solid theme-color('nutritionSecondary');
    span {
      color: theme-color('nutritionPrimary');
    }
    &:hover {
      background-color: transparent;
      border: 1px solid theme-color('nutritionSecondary');
      span {
        color: theme-color('nutritionPrimary');
      }
    }
  }

  .responsiveEmbedDivNutrition {
    width: 80%;
    margin: 0 auto;
    background-image: url(../../../../assets/imgs/nutrition/vsl/vsl2-min.png);
    background-image: -webkit-image-set(
      url(../../../../assets/imgs/nutrition/vsl/vsl2-min.png) type('image/png'),
      url(../../../../assets/imgs/nutrition/vsl/vsl2-min.webp)
        type('image/webp')
    );

    background-image: -moz-image-set(
      url(../../../../assets/imgs/nutrition/vsl/vsl2-min.png) type('image/png'),
      url(../../../../assets/imgs/nutrition/vsl/vsl2-min.webp)
        type('image/webp')
    );
    background-image: -o-image-set(
      url(../../../../assets/imgs/nutrition/vsl/vsl2-min.png) type('image/png'),
      url(../../../../assets/imgs/nutrition/vsl/vsl2-min.webp)
        type('image/webp')
    );
    background-image: -ms-image-set(
      url(../../../../assets/imgs/nutrition/vsl/vsl2-min.png) type('image/png'),
      url(../../../../assets/imgs/nutrition/vsl/vsl2-min.webp)
        type('image/webp')
    );
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .responsiveEmbedDivNutrition:hover {
    cursor: pointer;
  }
  .responsiveEmbedDivNutrition:hover .playIcon {
    color: theme-color('primary');
  }

  .playContainerNutrition {
    // width: 100px;
    // height: 60px;
    background-color: rgba(0, 0, 0, 0.473);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .playDivNutrition {
    width: 100px;
    height: 100px;
    border: 1px solid theme-color('nutritionSecondary');
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: 0.3s;
    pointer-events: none;
  }
  .playIconNutrition {
    transition: color 0.3s;
  }
  .playContainerNutrition:hover .playDivNutrition {
    opacity: 0.8;
    @media (min-width: 992px) {
      border: none;
    }
  }
  .playContainerNutrition:hover .playIconNutrition {
    color: theme-color('nutritionSecondary') !important;
    @media (min-width: 992px) {
      border: none;
    }
  }
  .playContainerNutrition:hover .playIconNutrition path {
    // stroke: none;
    color: theme-color('nutritionSecondary');
  }

  .playIconNutrition {
    font-size: 2em;
    color: transparent;
  }
  .playIconNutrition path {
    transition: color 0.3s;
    stroke-width: 20;
    stroke: theme-color('nutritionSecondary');
    color: transparent;
  }

  @media (max-height: 600px) and (min-width: 992px) {
    .RootVideoNutrition {
      padding-top: 5vw;
    }
  }

  @media (max-width: 991px) {
    .RootVideoNutrition {
      padding-top: 30vw;
      padding-bottom: 15vw;
    }
    .playDivNutrition {
      width: 25vw;
      height: 25vw;
      max-width: 100px;
      max-height: 100px;
    }
    .playDivNutrition svg {
      width: 8vw;
    }
    .responsiveEmbedDivNutrition {
      width: 100%;
    }
  }
}
