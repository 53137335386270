.deadLineFunnelCustom {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #c381db 30%, #4e92f9 100%);

  position: fixed;
  top: 0;
  left: 0;

  z-index: 1032;
  .textStroke,
  h4 {
    margin: 0;
    color: theme-color('titleColor');
  }

  h4 {
    margin-right: 10px;
  }
  h2 {
    text-transform: lowercase;
    color: theme-color('titleColor') !important;
    background: transparent;
    background-clip: unset;
    -webkit-text-fill-color: unset;
  }

  h4,
  h2 {
    font-size: 50px;
    @media (max-width: 991px) and (min-width: 500px) {
      font-size: 4.5vw;
      line-height: 4.5vw;
    }
    @media (max-width: 499px) {
      font-size: 5vw;
      line-height: 5vw;
    }
  }
  .deadLineWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 991px) {
      // display: block;
      text-align: center;
    }
  }

  &.deadLineFunnelCustomNutri {
    background: theme-color('nutritionPrimary');

    .textStroke,
    h4 {
      font-family: 'Agrandir';
      font-weight: 400;
      font-style: normal;
      color: theme-color('nutritionBgColor');
    }

    h4 {
      margin-right: 10px;
    }
    h2 {
      font-family: 'Agrandir';
      font-weight: 400;
      font-style: normal;
      color: theme-color('nutritionBgColor') !important;
    }
  }

  @media (max-width: 991px) and (min-width: 500px) {
    // height: 15vw;
    max-height: 100px;
    // padding: 0.5em 0;
  }
  @media (max-width: 499px) {
    height: 23vw;
  }
}
