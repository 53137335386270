.oliviaPage {
  .RootTransfrom {
    z-index: 3;
    position: relative;
    .elipse {
      width: 80vw;
      height: 80vw;

      z-index: 1;
    }

    .rowSection {
      z-index: 3;
    }
  }
}
