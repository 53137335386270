.nutritionPage {
  .nutritionJumbotronHome {
    background-color: transparent;
    padding-top: 0;
  }

  .homeCol .pTextNutrition {
    // width: 60%;
  }

  .homePTextDiv {
    width: 60%;
    margin: 0 auto;
  }

  .RootHomeNutrition .nutritionSecondaryButtonStyle {
    background-color: theme-color('nutritionSecondary');
    border: 1px solid theme-color('nutritionSecondary');
    span {
      color: theme-color('nutritionPrimary');
    }
    &:hover {
      background-color: transparent;
      border: 1px solid theme-color('nutritionSecondary');
      span {
        color: theme-color('nutritionPrimary');
      }
    }
  }

  .circleTextAroundContainer {
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .RootHomeNutrition .circleTextAroundContainer {
    bottom: -50px;
    // z-index: -1;
  }
  .circleTextAround {
    position: relative;

    width: 150px;
    height: 150px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
  }
  .circleTextAroundImg {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: 4s infiniteRotation infinite linear;
  }

  // path {
  //   stroke:red;
  //   stroke-width:4px;
  //   fill:papayawhip;
  // }

  .aniamtionOnLoad {
    animation: animLoadedContent 1s cubic-bezier(0.7, 0, 0.3, 1) both;
  }

  .homeImagesNutrition {
    //   @media (min-width: 1800px) and (min-height: 1001px) {
    //     .homeImages {
    //       width: auto;
    //       max-height: 800px;
    //     }
    //   }
    //   @media (max-width: 1799px) and (min-height: 1001px) {
    //     .homeImages {
    //       width: 80%;
    //       max-height: auto;
    //     }
    //   }

    //   @media (max-height: 1000px) and (min-width: 992px) {
    //     .homeImages {
    //       width: 80%;
    //       height: auto;
    //     }
    //   }
    //   @media (max-width: 991px) {
    //     .homeImages {
    //       width: 80%;
    //     }
    //   }

    width: 90%;
  }

  .homeParallaxMouseDiv {
    max-width: 85%;
  }

  .homeImages2 {
    width: 40%;
    max-width: 350px;
    bottom: 0;
    top: auto !important;
  }
  .homeImages3 {
    width: 50%;
    max-width: 450px;
    bottom: 0;
    right: 0;
    left: auto !important;
    top: auto !important;
  }
  .homeImages4 {
    width: 30%;
    max-width: 200px;
    right: 0;
    left: auto !important;
    // top: -5vw !important;
  }

  .navLinkNutrition a {
    color: theme-color('nutritionPrimary');
  }

  .nutritionJumbotronHome .nutritionSecondaryButtonStyle {
    // width: fit-content;
    text-align: center;
    margin-top: 10vw;
  }

  @media (max-height: 1000px) and (min-width: 992px) {
    .homeImagesNutrition {
      width: 100% !important;
    }
  }
  @media (min-width: 991px) and (max-width: 1440px) {
    .RootHomeNutrition .circleTextAroundContainer {
      bottom: -3vw;
    }
    .RootHomeNutrition .circleTextAround {
      width: 10vw;
      height: 10vw;
    }
  }
  @media (max-width: 991px) {
    .RootHomeNutrition .homeColDesktop {
      padding-left: 0;
      padding-right: 0;
    }
    .RootHomeNutrition .circleTextAround {
      width: 70vw;
      height: 70vw;

      max-width: 150px;
      max-height: 150px;
    }
    .RootHomeNutrition .circleTextAround svg {
      width: 15vw;
    }

    .nutritionJumbotronHome .fitContentCenter:nth-child(1) {
      margin-bottom: 10vw;
    }
    .nutritionJumbotronHome .fitContentCenter:nth-last-child(3) {
      margin-top: 0;
    }
    .homeImagesNutrition {
      width: 100%;
      padding: 5vw 0;
    }
    .homePTextDiv {
      width: 100%;
    }

    .RootHomeNutrition .circleTextAroundContainer {
      position: relative;
      bottom: 0;
    }

    .circleTextAroundContainer {
      // z-index: -1;
    }

    .homeParallaxMouseDiv {
      max-width: 80%;
      margin: 0 auto;

      position: relative;
    }

    .homeImages2,
    .homeImages3,
    .homeImages4 {
      position: absolute;
    }
    .homeImages2 {
      width: 30%;
      left: 0;
    }
    .homeImages3 {
      width: 30%;
    }
    .homeImages4 {
      width: 30%;
      top: -10vw;
    }
    .nutritionJumbotronHome .nutritionSecondaryButtonStyle {
      margin-bottom: 10vw;
    }
    .RootHomeNutrition .nutritionSecondaryButtonStyle {
      background-color: #f45539;
      border: 1px solid #f45539;
    }
  }
}
