.nutritionPage {
  /* BACKGROUND ROW */

  .RootGallerySlider {
    height: auto;
    background-color: transparent;
    padding-top: 7.5vw;
    padding-bottom: 7.5vw;
    display: flex;
    justify-content: center;
  }

  /* CONTENT */
  .RootGallerySlider-inside-nutrition {
    max-width: 2000px;
    width: 100%;
  }

  // @media(min-width: 1440px) {
  //   .RootGallerySlider {
  //     justify-content: center;
  //   }
  //   .RootGallerySlider-inside {
  //     max-width: 80%;
  //   }

  // }
  @media (max-width: 991px) {
    .RootGallerySlider {
      padding-top: 15vw;
      padding-bottom: 15vw;
      overflow: hidden;
    }
    .RootGallerySlider-inside-nutrition {
      width: 80%;
    }
  }
}
