@font-face {
  font-family: 'Russo One';

  src: url('./Russo_One.eot'); /* IE9*/
  src: url('./Russo_One.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Russo_One.woff2') format('woff2'),
    /* chrome、firefox */ url('./Russo_One.woff') format('woff'),
    /* chrome、firefox */ url('./Russo_One.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./Russo_One.otf') format('opentype');

  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
