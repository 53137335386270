.oliviaPage {
  .rowSection {
    .faqCol {
      .accordionCard {
        position: relative;
        border: none;

        width: 100%;

        margin-bottom: 2vw;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        background: transparent;

        .accordionToggle {
          background: transparent;
          border: none;
          padding: 2vw;
          cursor: pointer;

          .accordionToggleDiv {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .accordionToggleIcon {
              position: absolute;
              left: -6vw;

              // padding: 2vw;
              background-color: theme-color('transparent');
              border: 1px solid theme-color('secondary');
              border-radius: 50%;
              max-width: 80px;
              max-height: 80px;
              width: 5vw;
              height: 5vw;

              display: flex;
              justify-content: center;
              align-items: center;

              // svg,
              // path {
              //   background: transparent !important;
              //   fill: theme-color('secondary');
              // }

              @media (max-width: 991px) {
                width: 10vw;
                height: 10vw;

                left: -13vw;

                img {
                  width: 4vw;
                }
              }
            }
            h4 {
              margin-bottom: 0;
            }
            @media (max-width: 991px) {
              display: block;
            }
          }
          @media (max-width: 991px) {
            padding: 5vw;
          }
        }
        @media (max-width: 991px) {
          margin-bottom: 5vw;
          * {
            text-align: left;
          }
        }
      }
      &.faqColCta {
        margin-top: 5vw;

        @media (max-width: 991px) {
          .buttonWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .accordionFaq {
        .accordionBody {
          padding: 2vw;
          @media (max-width: 991px) {
            padding: 5vw;
          }
        }
      }
    }
  }
}
