.nutritionPage {
  .RootThanks {
    .thanksCol {
      p {
        color: theme-color('titleColor');
        span,
        a {
          color: theme-color('nutritionSecondary');
        }
        a {
          &:hover {
            color: theme-color('nutritionPrimary');
          }
        }
      }
      .thanksTitle {
        h2 {
          background: unset;
          -webkit-background-clip: unset;
          background-clip: unset;
          -webkit-text-fill-color: unset;
          span {
            color: theme-color('nutritionPrimary');
          }
        }
      }
      .nutritionSecondaryButtonStyle {
        background-color: theme-color('nutritionSecondary');
        border: 1px solid theme-color('nutritionSecondary');
        span {
          color: theme-color('nutritionPrimary');
        }
        &:hover {
          background-color: transparent;
          border: 1px solid theme-color('nutritionSecondary');
          span {
            color: theme-color('nutritionPrimary');
          }
        }
      }
    }
  }
}
