.RootHomeNutrition {
  @extend .RootHome;
  position: relative;
  margin-top: 0;
  margin-bottom: 5vw;

  &.RootHomeNutrition-promo {
    margin-top: 100px !important;
    @media (max-width: 991px) and (min-width: 500px) {
      margin-top: 100px !important;
    }
    @media (max-width: 499px) {
      margin-top: 23vw !important;
    }
  }
}

@media (max-width: 991px) {
  .RootHomeNutrition {
    margin-bottom: 0;
  }
  .RootHomeNutrition .RootHome-inside {
    max-width: 90%;
  }
}
