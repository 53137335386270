.oliviaPage {
  .RootFooter {
    z-index: 1;
    // background-color: theme-color("primary");
    height: auto;
    // padding: 250px 50px 50px 50px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    position: relative;

    padding-top: 5vw;
    padding-bottom: 1vw;
    .rowSection {
      @media (max-width: 1600px) {
        padding-left: 5vw;
      }
      @media (max-width: 991px) {
        max-width: 100%;
        padding: 0 5vw;
      }
    }
    .footerOliImg,
    .footerBgImg {
      position: absolute;
      bottom: 0;
      height: auto;
    }
    .footerOliImg {
      right: 10vw;
      width: 30vw;
    }
    .footerBgImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
      left: 0;
    }

    .footerLogo {
      width: 80%;
      margin-bottom: 2vw;
      @media (max-width: 991px) {
        margin-bottom: 8vw;
      }
    }
    @media (max-width: 991px) {
      margin-left: 0 !important;
    }

    .footerCol {
      display: flex;
      justify-content: center;
      align-items: center;

      .footerColWrapper {
        // width: 100%;
        .brandCol {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .footerColDiv {
          display: flex;
          align-items: center;
          // justify-content: center;

          &:nth-child(1) {
            padding-left: 3em;
          }
          @media (max-width: 991px) {
            justify-content: center;
            &:nth-child(1) {
              padding-left: 0;
            }
          }
          // &:nth-child(2) {
          //   justify-content: flex-start;
          // }

          .footerSubTitle .textStroke {
            -webkit-text-stroke: 1px theme-color('primary');
            // color: theme-color('primary');
          }
        }
      }
      .footerFaceText {
        margin-top: 2vw;
        @media (max-width: 991px) {
          margin-top: 4vw;
        }
      }
      @media (max-width: 991px) {
        padding: 0;
      }
    }
  }

  .listGroupItemFooter a {
    text-decoration: none;
    color: theme-color('secondary');
    transition: 0.3s;

    &:hover {
      color: theme-color('textColor');
    }
  }

  .brandFooter {
    color: theme-color('secondary');
    align-items: flex-start !important;
  }

  .listGroupItemFooter {
    background-color: transparent;
    border: none;
    padding: 0;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1vw;
    @media (max-width: 991px) {
      margin-bottom: 3vw;
    }
  }

  .listGroupTitle {
    padding: 0;
    //  padding-bottom: 1em;

    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 1vw;
    @media (max-width: 991px) {
      display: block;
      margin-bottom: 4vw;
    }
  }

  .powered {
    position: absolute;
    bottom: 15px;
    right: 30px;
    // margin-top: 2vw;
    color: theme-color('darkColor');

    a {
      color: theme-color('secondary');
    }
    @media (max-width: 991px) {
      margin-top: 8vw;
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }
  }

  .powered a {
    color: theme-color('primary2');
    transition: color 0.3s;
    &:hover {
      color: theme-color('titleColor');
      text-decoration: none;
    }
  }
  .listGroupItemFooter a {
    cursor: pointer;
  }

  @media (max-width: 991px) {
    .RootFooter {
      padding-top: 10vw;
      display: flex;
      justify-content: center;
      overflow: hidden;
      padding-bottom: 10vw;
    }

    .socialButtons button {
      width: 50px;
      height: 50px;
    }

    .socialButtonsToolbar .btn {
      padding: 0;
    }

    .copyText:nth-child(1) {
      margin-bottom: 10px;
    }

    .brandFooter {
      margin-bottom: 50px;
    }

    .interestLinks {
      margin-bottom: 30px;
    }
  }
}
