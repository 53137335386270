.nutritionPage {
  .gridFaqContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .faqSectionRow {
    width: 80%;
    margin: 0 auto !important;
  }

  .faqRowButton {
    margin-top: 3em;
  }

  .faqCol {
    color: #000;
    height: fit-content;
    padding: 0;
    text-align: left;
    // margin-top: 100px;
  }

  .faqContainer {
    display: flex;
    justify-content: center;
  }

  .contentFaq {
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .horLineFaq {
    margin-right: 3vw;
  }

  .faqCol .list-group {
    margin-bottom: 50px;
  }

  .faqCol .list-group-item {
    border: none;
  }

  // TEXT
  .contentFaq {
  }

  .faqTitleBackground {
    background: transparent;
    margin-bottom: 100px;
  }

  .faqTitleBackground .titleLeftBorder {
    left: 200px;
  }

  .faqTitle {
    background: transparent;
    text-transform: uppercase;
    color: #000;
  }

  .faqP {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
  }

  /* BUTTONS STYLE */

  // .buttonCard {
  //    border-bottom: none !important;
  //    padding-left: 0 !important;
  //    padding-bottom: 0 !important;
  //    padding-top: 30px !important;
  //    margin-left: 0 !important;
  // }

  .buttonCard {
    padding-left: 0 !important;
    display: flex;
    justify-content: center;
  }

  .buttonCard .buttonText {
    line-height: inherit;
  }

  .contentFaq .buttonsGroup {
    margin-top: 30px;
    display: flex;
  }

  .buttonsFaq {
    padding-left: 20px;
    padding-right: 20px;
    width: 200px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: 0.5s;
    letter-spacing: 1.82px;
    text-decoration: none !important;

    font-family: 'Bruno Ace';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    //  line-height: 75px;
    /* or 469% */

    display: flex;
    align-items: center;
    text-transform: uppercase;
  }

  .buttonsFaq span {
  }

  // ACCORDION

  .cardFaqContent {
    width: 100%;
    margin: 0 auto;
  }

  .cardFaqContent .card {
    border: 0;
    // padding-left: 70px;
    // margin-top: 50px;
    background-color: transparent;
    padding-bottom: 3vw;
  }

  .faqCardsAccordion {
    border-bottom: 1px solid theme-color('nutritionPrimary') !important;
  }

  .cardFaqContent .card:nth-child(1) {
    margin-top: 0;
  }

  .cardFaqContent .card-header {
    background: transparent;

    border-bottom: none;

    display: flex;

    align-items: center;
    text-transform: uppercase;
  }

  .cardFaqContent .card-body {
    color: #000;
  }

  .courseContentIcon {
    cursor: pointer;
    position: absolute;
    padding: 15px 20px;
    border-radius: 50%;
    color: #fff;
  }

  .courseContentIcon svg path {
    color: #fff !important;
  }

  .faqContentIcon .fa-plus {
  }

  @media (max-width: 991px) {
    .faqSectionRow {
      width: 90%;
      margin: 0 auto !important;
    }

    .faqTitleBackground .titleLeftBorder {
      left: 0;
    }

    .contentFaq {
      padding-top: 0;
      padding-bottom: 0;
    }

    .faqTitleBackground {
      margin-bottom: 50px;
    }

    .cardFaqContent .card-header {
      font-weight: 900;
      font-size: 16px !important;
      line-height: 22px !important;
    }

    .faqCol .list-group {
      width: 100%;
      margin: 0 auto;
    }

    // .buttonCard {
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    // }

    .buttonsFaq {
      width: 80%;
    }

    .textFaq {
      color: #000;
      font-size: 15.55px;
      font-family: 'Roboto';
      font-weight: 400;
    }

    .faqRowResp {
      background-color: #000;
    }

    .faqColFirst {
      margin-bottom: 50px;
    }

    .faqSecondCol {
      margin-top: 50px;
    }

    // TEXT
    .cardFaqContent .card-header {
      font-size: 3vw !important;
      line-height: 4vw !important;
    }

    .cardFaqContent .card-body {
      font-size: 3vw;
      line-height: 4vw;
    }
  }

  @media (max-width: 666px) {
    .courseContentIcon {
      padding: 2vw 3vw;
    }
  }
}
