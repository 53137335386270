.oliviaPage {
  .customArrows {
    background-color: transparent;
    width: 100px;
  }
  .slick-arrow {
    position: absolute;
    width: 30% !important;
    height: 100% !important;
    z-index: 10;

    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .slickPrev,
  .slickNext {
    position: absolute;
    z-index: 9;

    border: none;
    width: 3.5vw !important;
    height: 3.5vw !important;
    // border-radius: 50% !important;
    padding: 10px !important;
    transition: 0.3s;

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    svg {
      transition: transform 0.3s;
    }
    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
    @media (max-width: 991px) {
      width: 10vw !important;
      height: 10vw !important;
    }
  }

  .sliderMouse {
    pointer-events: none;
  }
  .sliderMouse .slickPrev,
  .sliderMouse .slickNext {
    pointer-events: auto;
  }

  .slickNext {
    right: -5vw !important;
  }
  .slickPrev {
    left: -5vw !important;
  }

  .slickPrev svg,
  .slickNext svg {
    width: 100% !important;
    height: auto;
  }

  .slick-prev:before,
  .slick-next:before {
    content: '' !important;
  }

  .slick-prev,
  .slick-next {
    margin: 0 !important;
    border: none !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .slick-prev {
    left: 0 !important;
  }
  .slick-next {
    right: 0 !important;
  }

  @media (max-width: 991px) {
    .slickPrev,
    .slickNext {
      width: 10vw;
      height: 10vw;
      max-width: 10vw;
      max-height: 10vw;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9;
    }

    .slickPrev {
      left: -13vw !important;
      @media (max-width: 991px) {
        left: -10vw !important;
      }
    }
    .slickNext {
      right: -13vw !important;
      @media (max-width: 991px) {
        right: -10vw !important;
      }
    }
    .slickPrev svg,
    .slickNext svg {
      width: 100% !important;
      // height: 100% !important;
    }
    .sliderMouse {
      background: transparent !important;
    }
  }
}
