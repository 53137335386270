@font-face {
  font-family: 'Richard Hamilton';

  src: /* IE6-IE8 */ url('./Richard\ Hamilton.woff2') format('woff2'),
    /* chrome、firefox */ url('./Richard\ Hamilton.woff') format('woff'),
    /* chrome、firefox */ url('./Richard\ Hamilton.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./Richard\ Hamilton.otf') format('opentype');

  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Richard Hamilton';

  src: /* IE6-IE8 */ url('./Richard\ Hamilton\ Italic.woff2') format('woff2'),
    /* chrome、firefox */ url('./Richard\ Hamilton\ Italic.woff') format('woff'),
    /* chrome、firefox */ url('./Richard\ Hamilton\ Italic.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./Richard\ Hamilton\ Italic.otf') format('opentype');

  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
