.nutritionPage {
  /* BACKGROUND ROW */

  .RootPhoto {
    height: auto;

    background-color: theme-color('nutritionBgColor');
    background-image: url('../../../../assets/imgs/nutrition/backgroundSection-min.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    padding-top: 2vw;
    padding-bottom: 2vw;
  }

  /* CONTENT */
  .RootPhoto-inside {
    background-color: theme-color('nutritionBgColor');
    background-image: url('../../../../assets/imgs/nutrition/backgroundSection-min.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    padding-top: 2vw;
    padding-bottom: 2vw;

    width: 100%;
  }

  .photoCol {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .photoCol picture {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inner-imgPhoto {
    max-width: 600px;
    position: relative;
    bottom: -7vw;
  }
  .photoImg {
    width: 70%;
    // max-width: 600px;
    // position: relative;
    // bottom: -7vw;
  }

  // IMG EFFECT
  // .item__img-wrap {
  // 	--aspect-ratio: 1/1.5;
  // 	overflow: hidden;
  // 	width: 500px;
  // 	max-width: 100%;
  // 	padding-bottom: calc(100% / (var(--aspect-ratio)));
  // 	will-change: transform;
  // }

  // .item:first-child .item__img-wrap {
  // 	--aspect-ratio: 8/10;
  // 	--image: url(https://tympanus.net/Tutorials/SmoothScrollAnimations/img/1.jpg);
  // }

  // .item:nth-child(2) .item__img-wrap {
  // 	width: 1000px;
  // 	--aspect-ratio: 120/76;
  // 	--image: url(https://tympanus.net/Tutorials/SmoothScrollAnimations/img/2.jpg);
  // }

  .section-parallax {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .parallax-content {
    background-image: url('https://images.unsplash.com/photo-1597098494674-36c3813a65f3?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ');
    background-size: cover;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 120%;
  }

  .section {
    background-color: #111111;
  }

  .section-mood-image {
    margin-top: 80px;
    height: 70vh;
  }

  // //

  @media (max-width: 991px) {
    .photoImg {
      width: 100%;
    }
    .chilliImgContainer {
      padding-top: 5vw;
      padding-bottom: 5vw;
      justify-content: flex-end !important;
    }
    .chilliImg {
      width: 40%;
      transform: scaleX(-1);
    }
    .forMeDivNutritionDesp {
      top: 0 !important;
    }
  }
}
