.oliviaPage {
  .rowSection {
    .forMeCol {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      & > div {
        position: relative;
        width: 80%;
        z-index: 1;
        padding: 20vw 10vw 20vw 15vw;
        left: -7vw;
        h2 {
          margin-bottom: 1vw;
        }
        .forMeBg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          width: 100%;
          height: 100%;
          object-fit: contain;
          z-index: -1;

          @media (max-width: 1500px) {
            transform: translateY(-48%) translateX(-50%);
          }
        }

        .culonasCrew {
          width: 10vw;
          bottom: 5vw;
          right: 0;
          @media (max-width: 991px) {
            width: 30vw;
            right: auto;
            left: 5vw;
            bottom: -60vw;
          }
        }
        @media (max-width: 991px) {
          padding: 20vw 10vw 35vw 5vw;
          left: 0;
        }
      }
      @media (max-width: 991px) {
        padding: 0;
        & > div {
          width: 100%;
          .forMeBg {
            object-fit: unset;
            height: auto;
            transform: translateY(-48%) translateX(-50%) scale(1.1);
          }
          h2 {
            text-align: left;
          }
        }
      }
    }
  }
}
