html[data-scroll-direction='down'] #navbarNutri {
  transform: translate3d(0, -100%, 0);
}
html[data-scroll-direction='down'] .navbarPromo {
  transform: translate3d(0, -150%, 0) !important;
}
.nutritionPage {
  #navbarNutri {
    transition: transform 0.3s;

    &.nav-promo {
      margin-top: 100px !important;
      @media (max-width: 991px) and (min-width: 500px) {
        margin-top: 100px !important;
      }
      @media (max-width: 499px) {
        margin-top: 23vw !important;
      }
    }
  }
  .navContentNutrition {
    margin-right: 10%;
    transition: 0.3s;
    @media (max-width: 1400px) {
      & {
        margin-right: 0;
      }
    }
  }
  .navContentNutrition:nth-last-child(1) {
    margin-right: 0 !important;
  }

  .brandNameNutrition {
    display: flex;
    align-items: center;
    color: #fff !important;
    position: relative;
    transition: 0.3s;
    height: 100%;
    left: 0 !important;
  }

  .nav-color-scroll-nutrition {
    cursor: none;
    background-color: transparent !important;
    padding-left: 10vw;
    padding-right: 10vw;
    @media (max-width: 991px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .nav-color-scroll-nutrition *:hover {
    cursor: none;
  }

  .navBurger {
    position: absolute;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%);
  }

  // HAMBURGER BUTTON
  .toggle-btn {
    // position: fixed;
    // margin: 1.4em;
    // padding: 1em;
    width: 40px;
    height: 12px;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
  }
  span.hambOne,
  span.hambTwo {
    position: absolute;
    width: 40px;
    height: 2px;
    background: theme-color('nutritionPrimary');
  }

  span.hambTwo {
    margin-top: 12px;
  }

  .navbar-nav .nutritionButtonStyle span {
    color: theme-color('nutritionPrimary');
  }

  // OVERLAY
  .menuOverlay {
    z-index: 999;
    position: fixed;
    background: theme-color('nutritionBgColor');
    background-color: theme-color('nutritionBgColor');
    background-image: url('../../../imgs/nutrition/background-min.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    height: 100vh;
    width: 100%;
    // top: -100%;
    cursor: none;
    visibility: hidden;

    &.nav-promo {
      margin-top: 100px !important;
      @media (max-width: 991px) and (min-width: 500px) {
        margin-top: 100px !important;
      }
      @media (max-width: 499px) {
        margin-top: 23vw !important;
      }
    }
  }
  .menuOverlay *:hover {
    cursor: none;
  }

  .menuOverlay a {
    transition: color 0.3s;
  }
  .menuOverlay .navLink:hover {
    color: theme-color('nutritionSecondary');
  }

  .dataOverlay {
    // padding: 8em 0 0 2em;
    text-align: left;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .dataOverlay ul {
    padding: 0;
  }
  .dataOverlay li {
    padding: 0;
    margin-bottom: 2vw;
  }
  .dataOverlay li:nth-child(odd) {
    margin-right: 10vw;
  }
  .dataOverlay li:nth-child(even) {
    margin-left: 10vw;
  }
  .dataOverlay ul,
  .dataOverlay li {
    text-decoration: none;
    outline: none;
    list-style: none;
  }

  .navMenuPowered {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);

    color: theme-color('nutritionSecondary');

    cursor: none;
  }
  .navMenuPowered:hover {
    color: theme-color('nutritionSecondary');
  }

  .navLinkNutrition.nutritionButtonStyle {
    @extend .h2TitleNutrition;
    position: relative;
    text-transform: uppercase;
    vertical-align: middle;
  }
  .navLinkNutrition.nutritionButtonStyle::before {
    z-index: -1;
    height: 100%;
  }

  .navLinkNutrition {
    color: theme-color('nutritionPrimary');
  }

  .nav-color-scroll-nutrition .nutritionSecondaryButtonStyle {
    background-color: theme-color('nutritionSecondary');
    border-color: theme-color('nutritionSecondary');

    &:hover {
      border-color: theme-color('nutritionSecondary');
    }
    span {
      color: theme-color('nutritionPrimary');
    }
  }
  .nav-color-scroll-nutrition .nutritionSecondaryButtonStyle {
    margin-right: 0 !important;
  }

  @media (max-width: 991px) {
    .nav-color-scroll-nutrition .navbar-brand {
      width: 50%;
      padding-left: 5vw;
    }
    .nav-color-scroll-nutrition .navbar-brand svg {
      width: 100%;
    }
    .navBurger {
      left: auto;
      right: 0;
    }

    .dataOverlay {
      padding: 0;
    }

    .dataOverlay .h2TitleNutrition {
      line-height: 15vw !important;
    }
    .hamburgerButton:hover {
      background: transparent !important;
    }
  }
  @media (max-height: 700px) and (max-width: 991px) and (orientation: landscape) {
    .dataOverlay .h2TitleNutrition {
      font-size: 8vw !important;
      line-height: 8vw !important;
    }

    .navLinkNutrition {
      padding: 1vw 2vw !important;
    }
  }

  @media (max-height: 600px) and (max-width: 991px) and (orientation: landscape) {
    .dataOverlay .h2TitleNutrition {
      font-size: 6vw !important;
      line-height: 6vw !important;
    }
    .dataOverlay > div {
      position: relative;
      top: 5vw;
    }

    .navLinkNutrition {
      padding: 1vw 2vw !important;
    }
  }
  @media (max-height: 500px) and (max-width: 991px) and (orientation: landscape) {
    .dataOverlay .h2TitleNutrition {
      font-size: 5vw !important;
      line-height: 4vw !important;
    }

    .navLinkNutrition {
      padding: 1vw 2vw !important;
    }
  }

  @media (max-height: 400px) and (max-width: 991px) and (orientation: landscape) {
    .dataOverlay .h2TitleNutrition {
      font-size: 4vw !important;
      line-height: 2vw !important;
    }

    .navLinkNutrition {
      padding: 1vw 2vw !important;
    }
  }
}
