.oliviaPage {
  .RootForYou {
    position: relative;
    margin-top: -12vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .rowSection {
      z-index: 1;
      @media (max-width: 991px) {
        max-width: 100%;
      }
    }
    .culonasCrew {
      // display: none;
      right: 30vw;
      left: auto;
      bottom: 0;
      @media (max-width: 991px) {
        right: 8vw;
        left: auto;
        bottom: 13vw;
        display: block;
      }
    }
  }
}
