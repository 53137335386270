.nutritionPage {
  /* BACKGROUND ROW */

  .RootDonut {
    height: auto;
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding-top: 6vw;
    padding-bottom: 3vw;
  }

  /* CONTENT */
  .RootDonut-inside {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 90%;
  }

  .donutCol {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .donutImg {
    width: 70%;
  }

  @media (max-width: 991px) {
    .donutImg {
      width: 70%;
    }
  }
}
