.oliviaPage {
  .rowSection {
    .citeCol {
      @media (max-width: 991px) {
        h2 {
          text-align: left;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}
