.oliviaPage {
  .rowSection {
    .vslCol {
      text-align: center;
      .vslImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.vslColVid {
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
          margin-top: 2vw;
          width: 80%;

          .vslImg {
            filter: brightness(0.7);
          }
          @media (max-width: 991px) {
            width: 100%;
          }
        }
      }
      @media (max-width: 991px) {
        text-align: left;
      }
    }
  }
}
