.oliviaPage {
  .RootVsl {
    z-index: 1;
    position: relative;

    .elipse {
      width: 80vw;
      height: 80vw;

      z-index: 1;

      @media (max-width: 991px) {
        bottom: -50vw;
      }
    }

    .rowSection {
      z-index: 2;
    }
  }
}
