.oliviaPage {
  .RootCurves {
    // margin: -15vw 0;
    .rowSection {
      max-width: 100%;
      height: 75vw;
    }

    @media (max-width: 991px) {
      padding-top: 35vw;
    }
  }
}
