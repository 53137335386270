.oliviaPage {
  .RootCite {
    padding-top: 0;
    margin-top: -10vw;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .elipse {
      width: 80vw;
      height: 80vw;

      z-index: 1;
    }
    .citePicture {
      z-index: 2;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      position: relative;
      top: 0;
      right: 0;
      width: 100%;
      // @media (max-width: 991px) {
      //   width: 100%;
      // }
    }
    .bgImg {
      position: relative;
      top: 0;
      right: 0;
      width: 70vw;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
    .rowSection {
      position: absolute;
      z-index: 3;
      width: 58vw;
      margin-top: -10vw;
      @media (max-width: 991px) {
        width: 90%;
        margin-top: 0;
        top: 42vw;
      }
    }
  }
}
