.oliviaPage {
  .RootEarn {
    position: relative;
    margin-top: 10vw;
    z-index: 2;
    .earnPicture {
      @media (max-width: 991px) {
        width: 100%;
      }
    }
    .earnBg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;
      z-index: 2;

      @media (max-width: 991px) {
        // height: 400vw;

        object-position: 80% 0;
        object-fit: cover;

        position: relative;
        top: 0;
        transform: none;
      }
    }

    .rowSection {
      z-index: 3;

      @media (max-width: 991px) {
        position: absolute;
        top: 18vw;

        max-width: 100%;
      }
    }
    .culonasCrew {
      // display: none;
      width: 8vw;
      height: 8vw;
      right: 45vw;
      top: -10vw;
      left: auto;
      bottom: auto;
      @media (max-width: 991px) {
        width: 20vw;
        height: 20vw;
        right: 55vw;
        top: -10vw;
        bottom: auto;
        display: block;
      }
    }
    @media (max-width: 991px) {
      // overflow-x: hidden;
    }
  }
}
