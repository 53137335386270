.oliviaPage {
  .RootFaq {
    // z-index: 2;
    position: relative;
    .elipse {
      width: 80vw;
      height: 80vw;
      top: -20vw;
      z-index: 1;
    }
    .rowSection {
      z-index: 3;
    }

    @media (max-width: 991px) {
      .oliImgPicture {
        width: 70%;
      }
      .faqOliImg {
        position: relative;
        bottom: 0;
      }
    }
  }
}
