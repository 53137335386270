.oliviaPage {
  .RootForMe {
    position: relative;

    padding-top: 0;
    margin-top: -45vw;

    z-index: 2;

    .rowSection {
      z-index: 2;
      position: relative;

      @media (max-width: 991px) {
        max-width: 100%;
      }
    }
    @media (max-width: 991px) {
      margin-top: -20vw;
    }
  }
}
