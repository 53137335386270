.nutritionPage {
  .pricesContainerNutrition {
    height: auto;
  }

  .pricesContainerNutrition .cardGeneralStyle {
    background-color: theme-color('nutritionSecondary');
    border: none;
  }

  .pricesContainerNutrition .circleTextAroundContainer {
    width: fit-content;
    height: fit-content;
    bottom: none;
    top: -75px;
    left: -50px;
  }
  .nutritionPricesContainer {
    margin-top: 3vw;
  }
  .beforePriceNutrition,
  .afterPriceNutrition {
    color: theme-color('nutritionBgColor') !important;
    font-family: 'Canopee';
  }
  .beforePriceNutrition,
  .afterPriceNutrition {
    color: theme-color('primary');
  }
  .beforePriceNutrition {
    font-family: 'Canopee';
    font-style: normal;
    font-weight: normal;
    font-size: 31px;
    line-height: 35px;

    margin-bottom: 0;
  }
  .afterPriceNutrition {
    font-family: 'Canopee';
    font-style: normal;
    font-weight: normal;
    font-size: 98px;
    line-height: 100px;
    /* or 87px */

    text-align: center;

    // margin-top: 35px;
    animation: heartbeat 4s infinite;
  }

  .pricesTextsNutrition {
    position: relative;
    margin-top: 3vw;
    margin-bottom: 3vw;
    @media (max-width: 991px) {
      width: 100%;
      margin: 0 auto;
    }
  }

  .priceDesc {
    @extend .faqTitleNutrition;
  }
  .priceDesc a {
    transition: color 0.3s;
    color: theme-color('nutritionPrimary');
  }
  .priceDesc a:hover {
    color: theme-color('nutritionSecondary');
  }

  .priceDescCol {
    margin-top: 3vw;
  }

  .striked-price-nutrition {
    position: relative;
    margin-bottom: 0 !important;
    margin-top: 0 !important;

    /* or 126px */

    text-align: center;
    color: theme-color('nutritionBgColor');
    &:before {
      position: absolute;
      align-content: center;
      content: '';
      width: 100%;
      top: 50%;
      left: 0;
      border-top: 2px solid;
      border-color: inherit;
      transform: rotate(0) translateY(-50%);
      color: theme-color('nutritionPrimary') !important;
      opacity: 1;
    }
  }

  .pricesContainerNutrition .cardGeneralStyle {
    padding: 2.5em 2.5em 4em 2.5em !important;
  }

  @media (max-width: 991px) {
    .pricesContainerNutrition .nutritionSectionTitle {
      margin-bottom: 30vw;
    }
    .pricesContainerNutrition .h2TitleNutrition {
      text-align: left;
    }
    .pricesContainerNutrition .titleIndent {
      padding-left: 5vw;
    }
    .pricesContainerNutrition .nutritionSectionTitle {
      // width: 90%;
    }

    .pricesContainerNutrition .circleTextAroundContainer {
      top: -20vw;
      left: 50%;
      right: 0;
      transform: translateX(-50%);
    }
    .pricesContainerNutrition .circleTextAround {
      position: relative;
      width: 40vw;
      height: 40vw;
    }

    .pricesContainerNutrition .cardGeneralStyle {
      padding: 10vw 7vw 15vw 7vw !important;
    }
    .pricesContainerNutrition .card-deck {
      position: relative;
    }

    .priceDescCol {
      max-width: 80%;
      margin: 0 auto;
    }

    .cardPrices .container {
      margin: 0;
      padding: 0;
      max-width: 100%;
    }
    .pricesCol {
      padding: 0;
    }
  }
}
