.oliviaPage {
  .rowSection {
    .curvesCol {
      padding: 0;
      position: relative;
      z-index: 1;

      top: 50%;
      transform: translateY(-50%);
      .curvesImg {
        z-index: 1;
      }
      .oliImg {
        z-index: -1;
        position: absolute;
        top: 10vw;
        right: 0;
        width: 53vw;
        @media (max-width: 991px) {
          width: 100%;
          top: -35vw;
        }
      }
    }
  }
}
