.nutritionPage {
  /* BACKGROUND ROW */

  .RootForMeNutrition {
    height: auto;
    background-color: transparent;

    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
  }

  /* CONTENT */
  .RootForMe-inside-nutrition {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 90%;
  }

  @media (min-width: 1440px) {
    .RootForMe-inside-nutrition {
      max-width: 90% !important;
    }
  }

  @media (max-width: 991px) {
    .RootForMeNutrition {
      padding-top: 15vw;
      padding-bottom: 15vw;
    }
  }
}
