.nutritionPage {
  /* BACKGROUND ROW */

  .RootEarnNutrition {
    height: auto;
    background-color: transparent;
    padding-top: 7.5vw;
    padding-bottom: 7.5vw;

    display: flex;
    justify-content: center;
  }

  /* CONTENT */
  .RootEarn-inside-nutrition {
    display: flex;
    height: 100%;
    max-width: 2000px;
    width: 80%;
  }

  @media (min-width: 1440px) {
    .RootEarn-inside-nutrition {
      max-width: 80%;
    }
  }
  @media (max-width: 991px) {
    .RootEarn-inside-nutrition {
      width: 90%;
    }
    .RootEarnNutrition {
      padding-top: 20vw;
      padding-bottom: 15vw;
    }
  }
}
