.oliviaPage {
  .RootHome {
    position: relative;
    width: 100%;
    height: 100vh;

    background-color: theme-color('primary');

    &.RootHomePromo {
      margin-top: 100px !important;
      @media (max-width: 991px) and (min-width: 500px) {
        margin-top: 100px !important;
      }
      @media (max-width: 499px) {
        margin-top: 23vw !important;
      }
    }

    .heroBgImg {
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
    }
    .heroBgVid {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      filter: brightness(0.8);
    }

    .rowSection {
      z-index: 2;
      // position: relative;

      .arrowDown {
        position: absolute;
        bottom: 3vw;
        right: 30vw;
        width: 8vw;

        .arrowDownImg {
          width: 100%;
          cursor: pointer;
          // height: 100%;
        }

        @media (max-width: 991px) {
          width: 18vw;
          right: 20vw;
        }
      }
    }

    @media (max-width: 991px) {
      height: auto;
      position: relative;
      align-items: flex-start;

      .heroBg {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 2;
      }
      .heroTitle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;

        padding: 2vw 5vw 5vw 5vw;
      }
      .rowSection {
        // position: absolute;
        max-width: 100%;
        z-index: 2;
        // bottom: 0;

        padding-top: calc(calc(#{$sectionPaddingSpaceMobile} * 3));
        padding-bottom: calc(calc(#{$sectionPaddingSpaceMobile} * 2));
      }
      .heroBgVid {
        // position: relative;
        // height: auto;
        // margin-top: -14vw;
        padding-top: calc(calc(#{$sectionPaddingSpaceMobile} * 5));
      }

      // padding-top: calc(calc(#{$sectionPaddingSpaceMobile} * 4));
    }
  }
}
