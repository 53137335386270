.nutritionPage {
  // .cardGeneralStyle {
  //   background: transparent;
  //   border: 1px solid theme-color('nutritionSecondary');
  //   border-radius: 0px 25px 0px 0px;
  //   max-width: 800px;
  //   margin-right: 25px !important;
  //   margin-left: 25px !important;
  // }
  // .cardGeneralStyle {
  //   padding: 2.5rem;
  // }

  .gallerySliderContainerNutrition .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gallerySliderContainerNutrition {
    @extend .earnContainer;
    width: 90%;
  }

  .gallerySliderContainerNutrition .h3TitleNutrition {
    padding-top: 0;
  }

  .gallerySliderContainerNutrition .slick-slide {
    margin: 0 10px;
  }

  .gallerySliderContainerNutrition .slick-list {
    margin: 0 -10px;
    overflow: visible;
  }

  .gallerySliderContainerNutrition .slickNext {
    right: 0 !important;
    pointer-events: none;
    // display: none !important;
  }

  .gallerySliderContainerNutrition .slickPrev {
    left: 0 !important;
    pointer-events: none;
    // display: none !important;
  }

  // EFFECT
  .inner--image {
    overflow: hidden;
  }

  @media (max-width: 991px) {
    .gallerySliderContainerNutrition .slick-prev {
      left: 0 !important;
    }
    .gallerySliderContainerNutrition .slick-next {
      right: 0 !important;
    }

    .gallerySliderContainerNutrition .slickNext {
      right: -5vw !important;
    }

    .gallerySliderContainerNutrition .slickPrev {
      left: -5vw !important;
    }
  }
}
