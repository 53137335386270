// FONTS
// LATO
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
// RICHARD HAMILTON
@import '../../fonts/richard_hamilton/style.scss';
// RUSSO ONE
@import '../../fonts/russo_one/style.scss';

.oliviaPage {
  * {
    color: theme-color('textColor');
  }
  h1 {
    font-family: 'Russo One';
    font-style: normal;
    font-weight: 400;
    font-size: 9vw;
    line-height: 7.5vw;
    color: theme-color('titleColor');

    @media (max-width: 991px) {
      font-size: 20vw;
      line-height: 15.5vw;
    }
  }

  h2 {
    font-family: 'Russo One';
    font-style: normal;
    font-weight: normal;
    font-size: 3vw;
    line-height: 3.5vw;

    margin-bottom: 3vw;

    //

    background: theme-color('titleColorGradient');
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    &.h2White {
      background: none;
      -webkit-background-clip: unset;
      background-clip: unset;
      -webkit-text-fill-color: unset;
      color: theme-color('titleColor');
    }

    @media (max-width: 991px) {
      font-size: 6vw;
      line-height: 6.5vw;
      text-align: center;

      margin-bottom: 6vw;
    }
  }

  h3 {
    font-family: 'Russo One';
    font-style: normal;
    font-weight: normal;
    font-size: 2vw;
    line-height: 2.3vw;

    color: theme-color('titleColor');

    @media (max-width: 991px) {
      font-size: 4vw;
      line-height: 4.3vw;
    }
  }

  h4 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 1.5vw;
    line-height: 1.5vw;

    color: theme-color('primary');
    @media (max-width: 991px) {
      font-size: 4vw;
      line-height: 4.5vw;
    }
  }

  h5 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 1.5vw;
    line-height: 1.5vw;

    color: theme-color('playBtn');
    &.beforeAfterText {
      margin: 1.5vw;
    }
    @media (max-width: 991px) {
      font-size: 4vw;
      line-height: 4.5vw;
    }
  }

  p,
  li {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 0.95vw;
    line-height: 1.3vw;

    @media (max-width: 1500px) {
      font-size: 1.2vw;
      line-height: 1.5vw;
    }

    &.pWhite,
    &.liWhite {
      color: theme-color('titleColor');
    }
    &.liBlack {
      color: theme-color('textColor') !important;
      &::before {
        background: theme-color('textColor');
      }
    }

    @media (max-width: 991px) {
      &.pSmaller {
        font-size: 3.5vw;
        line-height: 5vw;
      }
      font-size: 4vw;
      line-height: 5.5vw;
    }
  }
  .featuredText {
    color: theme-color('primary');
  }
  .boldText {
    font-weight: 700;
  }

  // BUTTON
  .buttonText {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 1.1vw;
    line-height: 1.6vw;

    @media (max-width: 991px) {
      font-size: 4vw;
      line-height: 5vw;
    }
  }

  //
  // FOOTER

  .footerTitle {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    line-height: 1vw;

    text-transform: uppercase;

    color: theme-color('titleColor');
    @media (max-width: 991px) {
      font-size: 6.5vw;
      line-height: 7vw;
    }
  }

  .footerText {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 0.85vw;
    line-height: 0.85vw;
    @media (max-width: 991px) {
      font-size: 4vw;
      line-height: 4vw;
    }
  }

  .powered {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 0.8vw;
    line-height: 0.8vw;

    @media (max-width: 991px) {
      font-size: 4vw;
      line-height: 4vw;
    }
  }
  .footerFaceText {
    color: theme-color('titleColor');
    font-size: 13px;
    line-height: 18px;
    @media (max-width: 1500px) and (min-width: 992px) {
      font-size: 1vw;
      line-height: 1.5vw;
    }
    @media (max-width: 991px) {
      font-size: 3vw;
      line-height: 3.5vw;
    }
  }

  // TEST
  .testImgWrap {
    &::before,
    &::after {
      @extend h5;
      font-size: 1vw;
      line-height: 1vw;
      @media (max-width: 991px) {
        font-size: 3vw;
        line-height: 3vw;
      }
    }
  }

  // MODULES
  .moduleNumber,
  .moduleHeader {
    color: theme-color('primary');
    font-style: normal;
  }
  .moduleHeader {
    font-family: 'Lato';
    font-weight: 900;
    font-size: 1.6vw;
    line-height: 1.6vw;
    @media (max-width: 991px) {
      font-size: 4.6vw;
      line-height: 4.6vw;
    }
  }
  .moduleNumber {
    font-family: 'Russo One';
    font-weight: normal;
    font-size: 6vw;
    line-height: 4.5vw;
    @media (max-width: 991px) {
      font-size: 10vw;
      line-height: 8.5vw;
    }
  }

  // Payment
  .method,
  .methodUnique {
    font-family: 'Lato';
    font-style: normal;
  }
  .method {
    font-weight: 900;
    font-size: 2vw;
    line-height: 2vw;
    @media (max-width: 991px) {
      font-size: 6vw;
      line-height: 6vw;
    }
  }
  .methodUnique {
    font-weight: 700;
    font-size: 1.4vw;
    line-height: 1.8vw;
    @media (max-width: 991px) {
      font-size: 4.4vw;
      line-height: 4.8vw;
    }
  }
  .paymentBeforeAfterText {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    &.beforeText {
      font-size: 1.2vw;
      line-height: 1.6vw;

      opacity: 0.3;

      @media (max-width: 991px) {
        font-size: 3vw;
        line-height: 3vw;
      }
    }
    &.afterText {
      font-size: 1.8vw;
      line-height: 2.4vw;
      @media (max-width: 991px) {
        font-size: 5vw;
        line-height: 5.6vw;
      }
    }

    color: theme-color('darkColor');
    @media (max-width: 991px) {
      font-size: 3vw;
      line-height: 3vw;
    }
  }
  .beforePrice,
  .afterPrice {
    font-family: 'Russo One';
    font-style: normal;
    font-weight: normal;
  }
  .beforePrice {
    position: relative;
    font-size: 2.5vw;
    line-height: 2.5vw;
    color: theme-color('darkColor');
    // -webkit-text-stroke: 1px theme-color('primary');

    opacity: 0.3;

    &:before {
      position: absolute;
      align-content: center;
      content: '';
      width: 100%;
      top: 50%;
      left: -10px;
      border-top: 2px solid;
      border-color: inherit;
      -webkit-transform: rotate(-15deg);
      -moz-transform: rotate(-15deg);
      -ms-transform: rotate(-15deg);
      -o-transform: rotate(-15deg);
      transform: rotate(15deg);
      color: theme-color('darkColor');
      opacity: 1;
    }
    @media (max-width: 991px) {
      font-size: 9vw;
      line-height: 9vw;
      width: fit-content;
      margin: 0 auto;
    }
  }
  .afterPrice {
    font-size: 7vw;
    line-height: 6vw;

    color: theme-color('darkColor');
    animation: heartbeat 4s infinite;

    @media (max-width: 991px) {
      font-size: 30vw;
      line-height: 25vw;
    }
  }
  //

  // NAVBAR
  .linksNav {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 0.9vw;
    line-height: 1vw;

    color: theme-color('titleColor') !important;

    .buttonText {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 900;
      font-size: 0.9vw;
      line-height: 1vw;
      @media (max-width: 991px) {
        font-size: 7vw;
        line-height: 7vw;
      }
    }
    svg,
    path {
      color: theme-color('titleColor') !important;
    }

    @media (max-width: 991px) {
      font-size: 7vw;
      line-height: 7vw;
    }
  }
  // NUMERIC PSEUDO
  .numericPseudo {
    position: relative;
    &::before {
      content: '';
      position: absolute;

      // FONT
      font-family: 'Microgramma D Extended';
      font-style: normal;
      font-weight: bold;
      font-size: 2.5vw;
      line-height: 2.8vw;

      color: transparent;
      -webkit-text-stroke: 1px theme-color('secondary');
      @media (max-width: 991px) {
        font-size: 10.5vw;
        line-height: 10.8vw;
      }
    }
  }

  .arrowSelectText {
    position: relative;
    &::before {
      content: '';
      position: absolute;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      max-width: 100px;
      max-height: 100px;
      width: 5vw;
      height: 5vw;
      left: -5vw - 0.5vw;
      top: 50%;
      transform: translateY(-70%);
    }

    @media (max-width: 991px) {
      & {
        &::before {
          width: 10vw;
          height: 10vw;
          left: -10vw - 2vw;
          top: 50%;
          transform: translateY(-70%);
        }
      }
    }
  }
}
