.RootUpSell {
  margin: 0;
  padding-top: 2vw;
  padding-bottom: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .rowSection {
    // padding-left: $nav-width;
    .upSellCol {
      display: flex;
      justify-content: center;
      align-items: center;
      & > div {
        width: 100%;
      }

      .oliBrandThank {
        width: 20vw;
        margin-bottom: 3vw;
        @media (max-width: 991px) {
          width: 60vw;
          margin-bottom: 8vw;
        }
      }

      .titleWrap {
        text-align: center;
        margin-bottom: 5vw;

        h2 {
          margin-bottom: 0.5em;
        }
        @media (max-width: 991px) {
          margin-bottom: 0;
        }
      }
      li,
      p {
        color: theme-color('secondary');
      }
      .vslDiv {
        width: 80%;
        position: relative;
        // right: -4vw;
        margin: 2vw auto 8vw auto;
        // margin-bottom: 8vw;

        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 991px) {
          width: 100%;
        }

        .vslPicture {
          width: 100%;
          transform: rotate(-1.71deg);

          .vslImg {
            width: 100%;
            // transform: rotate(-1.71deg);
            position: relative;
            // right: -4vw;
          }
        }
        @media (max-width: 991px) {
          width: 100%;
          margin-top: 8vw;
          margin-bottom: 12vw;
        }
      }
      .arrowSelectText {
        width: fit-content;

        &::before {
          background-image: url(../../imgs/thanksPage/arrowTextDown.svg);
          left: auto;
          right: -10vw;
        }
        @media (max-width: 991px) {
          & {
            width: 70%;
            &::before {
              right: -10vw - 2vw;
            }
          }
        }
      }

      .forYouColDiv {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .forYouListDiv {
          width: 80%;
          margin-left: 20%;
        }
        ul {
          padding-left: 0;
          .numericPseudo {
            padding: 2em 0;
            display: flex;
            //   align-items: center;
            &:nth-child(1) {
              &::before {
                content: '01';
              }
            }
            &:nth-child(2) {
              &::before {
                content: '02';
              }
            }
            &:nth-child(3) {
              &::before {
                content: '03';
              }
            }
            &:nth-child(4) {
              &::before {
                content: '04';
              }
            }
            &:nth-child(5) {
              &::before {
                content: '05';
              }
            }
            &::before {
              right: calc(100% + 30px);
              top: 50%;
              transform: translateY(-50%);
            }
          }

          &.vLeftLine {
            position: relative;
            &::before {
              content: '';
              position: absolute;
              height: 100%;
              background-image: url('../../imgs/thanksPage/leftLine.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              width: 10px;
              left: -20px;
            }
          }
        }
        h2 {
          margin-bottom: 1em;
        }
        @media (max-width: 991px) {
          width: 80%;
          margin-top: 0;

          .forYouTitleMobile {
            justify-content: center;
            align-items: center;
          }
          .arrowSelectText::before {
            content: none;
          }
        }
      }
      .buttonWrap {
        text-align: center;
        margin-top: 3vw;
        .buttonStyle {
          .progress__path {
            fill: theme-color('nutritionSecondary');
          }
          @media (max-width: 991px) {
            // margin-bottom: 8vw;
          }
        }
        h5 {
          margin: 2em 0;
          @media (max-width: 991px) {
            margin: 10vw 0;
          }
        }
        .lineDeco {
          @media (max-width: 991px) {
            margin-bottom: 15vw;
          }
        }
      }
    }
    .forYouCol.nutritionPage {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 991px) {
      padding-left: 0;
    }
  }

  @media (max-width: 991px) {
    & {
      padding-top: 20px;
      padding-bottom: 10vw;
      // padding-left: 0;

      //   .rowSection {
      //     height: auto;
      //     padding-bottom: 0;
      //     margin-top: 70px;
      //   }
    }
  }
}
