@keyframes elementIlumination {
  50% {
    color: theme-color('titleColor');
    -webkit-text-stroke: none;
  }
}
@keyframes elementIlumination2 {
  50% {
    color: theme-color('titleColor');
    -webkit-text-stroke: none;
  }
}
@keyframes elementIlumination3 {
  50% {
    color: theme-color('titleColor');
    -webkit-text-stroke: none;
  }
}
