.nutritionPage {
  .modulesColNutrition {
    & .flickity-viewport {
      // min-height: 500px;
    }
    & .flickity-slider {
      display: flex;
      align-items: center;
    }
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modulesDivNutrition {
    width: 100%;
    padding: 0 5vw;
    background-color: theme-color('nutritionBgColor');
    background-image: url('../../../../assets/imgs/nutrition/modules-min.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    overflow: hidden;
  }
  .modulesDivNutrition > div {
    // padding: 5vw 0;
    // overflow: hidden;
  }

  .modulesDivNutrition .slickSliderDiv {
    display: inline-table !important;
  }

  .modulesDivNutrition .cardGeneralStyle {
    // background-color: theme-color('nutritionSecondary');
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-top: 5vw;
    margin-bottom: 5vw;
  }

  .modulesDivNutrition .cardGeneralStyle .card-body .h3TitleNutrition,
  .modulesDivNutrition .cardGeneralStyle .card-body .pTextNutrition {
    color: theme-color('nutritionPrimary') !important;
  }
  .modulesCol .pTextNutrition {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .modulesDivNutrition .slick-list {
    // overflow: visible;
  }

  .modulesCardNumber {
    @extend .forMeCardNumber;
    background-color: theme-color('nutritionBgColor');
  }
  .modulesCardNumber .h3TitleNutrition {
    color: theme-color('nutritionPrimary') !important;
  }

  .modulesCircle {
    position: relative;
  }
  .modulesCircle::before {
    @extend .textCircle;
    content: url('../../../../assets/imgs/nutrition/icons/modulesCircle.svg') !important;
  }

  .modulesContentNutrition .titleIndent {
    padding-left: 2;
  }

  .modulesDivNutrition .vegetablesContainer {
    top: -100px;
    overflow: visible;
  }

  .modulesCol .slick-track {
    display: flex;
    align-items: center;
  }

  @media (min-width: 992px) {
    .modulesContentNutrition .nutritionSectionTitle {
      margin-bottom: 0;
    }
  }
  @media (max-width: 991px) {
    .modulesColContentNutrition {
      padding-right: 0;
      padding-left: 0;
    }
    .modulesColNutritionMobile {
      // margin-bottom: 15vw;
    }
    .modulesCardNumber {
      right: -3vw;
      top: -3vw;

      width: 10vw;
      height: 10vw;
    }

    .modulesDivNutrition ul {
      padding-inline-start: 5vw;
    }

    // .modulesCol .cardTitleNutrition {
    //   color: theme-color('nutritionBgColor');
    // }
    .modulesDivNutrition .cardGeneralStyle {
      margin-left: 0 !important;
      margin-right: 0 !important;

      padding: 8vw;
    }

    .modulesDivNutrition {
      padding: 5vw;
    }
    .modulesDivNutrition > div {
      padding: 0;
      overflow: visible;
    }
    .modulesDivNutrition .slickSliderDiv {
      padding: 9vw;
    }
    .modulesDivNutrition .slickPrev,
    .modulesDivNutrition .slickNext {
      max-width: 8vw;
      max-height: 8vw;
    }
    .modulesDivNutrition .slickPrev {
      left: -4vw !important;
    }
    .modulesDivNutrition .slickNext {
      right: -4vw !important;
    }

    .modulesContentNutrition .pTextNutrition {
      width: 90%;
    }
  }
  @media (max-width: 650px) {
    .modulesDivNutrition .slickPrev {
      left: -8vw !important;
    }
    .modulesDivNutrition .slickNext {
      right: -8vw !important;
    }
    .modulesDivNutrition .slickPrev,
    .modulesDivNutrition .slickNext {
      max-width: 10vw;
      max-height: 10vw;
    }
  }
  @media (max-width: 600px) {
    .modulesDivNutrition .slickPrev {
      left: -7vw !important;
    }
    .modulesDivNutrition .slickNext {
      right: -7vw !important;
    }
  }
  @media (max-width: 500px) {
    .modulesDivNutrition .slickPrev {
      left: -9vw !important;
    }
    .modulesDivNutrition .slickNext {
      right: -9vw !important;
    }
  }
  @media (max-width: 450px) {
    .modulesDivNutrition .slickPrev {
      left: -10vw !important;
    }
    .modulesDivNutrition .slickNext {
      right: -10vw !important;
    }
    .modulesDivNutrition .slickPrev,
    .modulesDivNutrition .slickNext {
      max-width: 10vw;
      max-height: 10vw;
    }
  }
  @media (max-width: 350px) {
    .modulesDivNutrition .slickPrev {
      left: -11vw !important;
    }
    .modulesDivNutrition .slickNext {
      right: -11vw !important;
    }
    .modulesDivNutrition .slickPrev,
    .modulesDivNutrition .slickNext {
      max-width: 10vw;
      max-height: 10vw;
    }
  }
}
