.oliviaPage {
  .mainContainer {
    // overflow-y: hidden;
    background-image: linear-gradient(to top, #f4f1fc 0%, #ffffff 50%);
  }

  .sectionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sectionPadding {
    padding: $sectionPaddingSpace 0;

    @media (max-width: 991px) {
      padding: $sectionPaddingSpaceMobile 0;
    }
  }
  .rowSection {
    width: 100%;
    max-width: 80%;

    @media (max-width: 991px) {
      max-width: 90%;
    }
  }

  .borderRadiusGeneral {
    border-radius: 30px;
  }

  // IMGS
  img {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    width: 100%;
  }

  // VSL
  .vslWrap {
    position: relative;
  }
  .playContainerWrapper {
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;

    cursor: pointer;

    top: 0;
    left: 0;
    &:hover {
      .playIcon {
        * {
          fill: transparent;
        }
      }
    }
  }
  .playContainer {
    max-width: 140px;
    max-height: 140px;
    width: 6vw;
    height: 6vw;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;

    background: transparent;
    border: none;

    border-radius: 50%;

    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    opacity: 0.5;

    transition: background-color 0.3s;

    .playIcon {
      stroke: theme-color('playBtn');
      stroke-width: 3;
      stroke-linecap: butt;
      stroke-dasharray: 0;

      width: 8vw;
      height: 8vw;
      max-width: 120px;
      max-height: 120px;

      * {
        transition: fill 0.3s;
      }

      path {
        color: theme-color('secondary') !important;
      }
    }

    @media (max-width: 991px) {
      width: 13vw;
      height: 13vw;
      .playIcon {
        font-size: 3.2vw;
        width: 13vw;
        height: 13vw;
      }
    }
  }

  .sphereD {
    position: absolute;
    width: 10vw;
    top: 0;
    left: 0;
    @media (max-width: 991px) {
      width: 20vw;
    }
  }

  .externalBorderGradient {
    background: linear-gradient(to left top, #fff, transparent) !important;
    border: 1px solid theme-color('secondary') !important;
  }
  .culonasCrew {
    position: absolute;
    width: 10vw;
    bottom: 5vw;
    right: 0;
    @media (max-width: 991px) {
      width: 30vw;
      right: auto;
      left: 5vw;
      bottom: -50vw;
    }
  }

  .bounce {
    -moz-animation: bounce 5s linear infinite;
    -webkit-animation: bounce 5s linear infinite;
    animation: bounce 5s linear infinite;
    @media (max-width: 991px) {
      -moz-animation: bounce 5s linear infinite;
      -webkit-animation: bounce 5s linear infinite;
      animation: bounce 5s linear infinite;
    }
  }

  @keyframes bounce {
    100% {
      transform: translateY(0);
    }
    60% {
      transform: translateY(30px);
    }
    // 60% {
    //   transform: translateY(-15px);
    // }
  }
  @media (max-width: 991px) {
    @keyframes bounce {
      100% {
        transform: translateY(0);
      }
      60% {
        transform: translateY(-10px);
      }
      // 60% {
      //   transform: translateY(-15px);
      // }
    }
  }

  .blur-up {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms;
  }

  .blur-up.lazyloaded {
    -webkit-filter: blur(0);
    filter: blur(0);
  }
}
