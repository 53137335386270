.oliviaPage {
  .bgMainThanks {
    // & > div {
    z-index: 1;
    background-color: theme-color('nutritionBgColor');
    position: relative;
    // overflow: hidden;
    &::before {
      content: '';
      background-color: theme-color('nutritionBgColor');
      // background-image: url('../../imgs/background/bgtexturePartThanks.jpg');
      // background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.7;
      z-index: 0;
    }
    .bgThankNutriWrap {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      background-image: url(../../imgs/nutrition/background-min.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
    }

    // }
  }
  .thanksPageDivWrap {
    .sectionsWrapper {
      width: 100%;
      padding-left: 0;
      @media (max-width: 991px) {
        // padding-left: 0;
      }
    }
  }
}
