.nutritionPage {
  /* CURSOR */
  .cursor {
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
  }
  .cursor--small {
    z-index: 11000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .currentCursor {
    border-radius: 50%;
    background: theme-color('nutritionPrimary');
    width: 5px;
    height: 5px;
  }
  .cursor--canvas {
    width: 100vw;
    height: 100vh;
    z-index: 12000;
  }

  .nutritionCursorNone,
  .nutritionCursorNone a,
  .nutritionCursorNone *:focus,
  .nutritionCursorNone *:hover {
    cursor: none;
  }
}
