.nutritionPage {
  /* BACKGROUND ROW */

  .RootPrices {
    height: auto;
    background-color: transparent;
    padding-top: 7.5vw;
    padding-bottom: 7.5vw;
  }

  /* CONTENT */
  .RootPrices-inside {
    position: relative;
    margin: 0 auto;
    width: 100%;
  }

  @media (max-width: 991px) {
    .RootPrices {
      height: auto;
      padding-top: 5vw;
      padding-bottom: 10vw;
    }
    .RootPrices-inside {
      width: 100%;
      padding: 0;
    }
  }
}
