.oliviaPage {
  .RootCourseInfo {
    padding-top: 5vw;
    .rowSection {
    }
    @media (max-width: 991px) {
      padding-top: 10vw;
    }
  }
}
