.oliviaPage {
  // CUSTOM ARROWS
  .slick-slider {
    .item {
      padding: 0 1vw;
      height: 100%;

      @media (max-width: 991px) {
        padding: 2vw 4vw;
      }
    }
  }

  .slick-dots ul li {
    width: 10px;
    height: 10px;

    @media (max-width: 991px) {
      width: 2vw;
      height: 2vw;
    }
  }
  .customDotStyle {
    width: 100%;
    height: 100%;
    background: theme-color('primary');
    border: 1px theme-color('primary') solid;
    border-radius: 50%;

    opacity: 0.5;

    &.customDotStyleSecondary {
      background: theme-color('secondary');
      border: 1px theme-color('secondary') solid;
    }
  }

  .slick-active {
    .customDotStyle {
      opacity: 1;
    }
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    // -webkit-flex: 1; /* Safari */
    // -ms-flex: 1; /* IE 10 */
    // flex: 1; /* Standard syntax */

    & > div,
    .cardStyle {
      height: 100%;
    }

    .sliderImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .customArrowsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1vw;
    .sliderArrowsCustom {
      background: theme-color('secondary');
      border: 1px solid theme-color('secondary');
      // border-radius: 50%;
      transform: skewX(-7deg);

      width: 2.5vw;
      height: 2.5vw;

      display: flex;
      justify-content: center;
      align-items: center;

      margin: 0 1vw;
      transition: background-color 0.3s;

      svg {
        width: 1.5vw;
        height: 1.5vw;
        path {
          stroke: theme-color('bgColor');
          transition: stroke 0.3s;
        }
      }

      &:hover {
        background: transparent;
        path {
          stroke: theme-color('titleColor');
        }
      }
      @media (max-width: 991px) {
        padding: 0;
      }
    }
    @media (max-width: 991px) {
      margin-top: 3vw;
      .sliderArrowsCustom {
        width: 8.5vw;
        height: 8.5vw;
        margin: 0 4vw;

        svg {
          width: 4.5vw;
          height: 4.5vw;
        }
      }
    }
  }
}
