.nutritionPage {
  .beforeAfterContainerNutrition {
    margin-bottom: 2vw !important;
  }

  .beforeAfterContainerNutrition .slick-prev {
    left: 0 !important;
  }
  .beforeAfterContainerNutrition .slick-next {
    right: 0 !important;
  }
  .beforeAfterContainerNutrition .slickPrev {
    left: -100px !important;
  }
  .beforeAfterContainerNutrition .slickNext {
    right: -100px !important;
  }

  .beforeAfterContainerNutrition .slickSliderDiv {
    padding-left: 25px;
    padding-right: 25px;
  }
  //

  //
  .testimoniosVid1Nutrition {
    // width: 100%;
    // height: 100%;
    background-image: url(../../../../assets/imgs/testimonios/test1.jpg);
    background-position: center;
    background-size: 100%;
    position: relative;
    // border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover .playIcon {
      color: theme-color('primary');
    }
    border-radius: 0;
  }
  .testimoniosVid2Nutrition {
    // width: 100%;
    // height: 100%;
    background-image: url(../../../../assets/imgs/testimonios/test2.jpg);
    background-position: center;
    background-size: 100%;
    position: relative;
    // border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover .playIcon {
      color: theme-color('primary');
    }
    border-radius: 0;
  }
  .testimoniosVid3Nutrition {
    // width: 100%;
    // height: 100%;
    background-image: url(../../../../assets/imgs/testimonios/test3.jpg);
    background-position: center;
    background-size: 100%;
    position: relative;
    // border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
  }

  .beforeAfterContainerNutrition .nutritionSecondaryButtonStyle {
    background-color: theme-color('nutritionSecondary');
    border: 1px solid theme-color('nutritionSecondary');
    span {
      color: theme-color('nutritionPrimary');
    }
    &:hover {
      background-color: transparent;
      border: 1px solid theme-color('nutritionSecondary');
      span {
        color: theme-color('nutritionPrimary');
      }
    }
  }

  @media (max-width: 1800px) and (min-width: 991px) {
    .beforeAfterContainerNutrition {
      max-width: 70%;
    }
  }
  @media (max-width: 1540px) {
    .RootBeforeAfter-inside-nutrition .slickPrev {
      left: -80px !important;
    }
    .RootBeforeAfter-inside-nutrition .slickNext {
      right: -80px !important;
    }
  }
  @media (max-width: 991px) {
    .beforeAfterContainerNutrition {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .beforeAfterContainerNutrition .slickPrev {
      left: -10vw !important;
    }
    .beforeAfterContainerNutrition .slickNext {
      right: -10vw !important;
    }
    .beforeAfterContainerNutrition .embed-responsive {
      border-radius: 0;
    }
    .RootBeforeAfter-inside-nutrition .marginTopButtons {
      margin-top: 10vw !important;
    }
    .RootBeforeAfter-inside-nutrition .priceDesc {
      text-align: center;
    }
  }
  @media (max-width: 500px) {
    .beforeAfterContainerNutrition .slickPrev {
      left: -8vw !important;
    }
    .beforeAfterContainerNutrition .slickNext {
      right: -8vw !important;
    }
  }
}
