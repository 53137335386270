.nutritionPage {
  /* BACKGROUND ROW */

  .RootHome {
    height: 100vh;
    background-color: transparent;
    // margin-top: 100px;
    display: flex;
    justify-content: center;
  }

  /* CONTENT */
  .RootHome-inside {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 80%;
  }

  @media (min-width: 1440px) {
    .RootAboutMe-inside {
      max-width: 1440px;
    }
  }

  @media (max-height: 1000px) and (min-width: 992px) {
    .RootHome {
      padding-top: 127px;
    }
  }

  @media (max-width: 991px) {
    .RootHome {
      height: auto;
      padding: 80px 0 0 0;
      background-size: cover;
      background-position: center;
      overflow: hidden;
      margin-top: 25px;
    }
  }
}
