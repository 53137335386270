.oliviaPage {
  .slides-container {
    position: relative;
    overflow: hidden;
    display: flex;
    // flex: 1;
    height: 50vh;
    .slide {
      position: absolute;
      // font-size: 90px;
      // font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: calc(100% / 2);
      /*   width: 100vw; */
      @media (max-width: 991px) {
        width: calc(100% / 2 + 100% / 5);
      }

      &.slideSnapped {
        width: calc(100% / 3);
      }
    }

    .slides-inner {
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;

      &.slides-inner-snapped {
        // margin-left: $nav-width;
        overflow: visible;
        @media (max-width: 991px) {
          margin-left: 0;
        }
      }
    }
  }
}
