.oliviaPage {
  .rowSection {
    .earnCol {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      h2 {
        background-color: #f67ba4;
      }
      .earnCardDeck {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .earnCard {
        // margin-bottom: 15px;
        width: 100%;
        background: theme-color('primary');
        border-radius: 50vw;
        border: 4px dashed theme-color('bgColor');
        margin-top: 2%;

        flex-direction: row;

        &::before {
          content: '';
          float: left;
          padding-top: 100%;
        }
        @media (max-width: 991px) {
          border-width: 1vw;

          // width: 50%;
          &::before {
            content: none;
          }
        }

        .cardBody {
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            color: theme-color('secondary');
            margin-bottom: 0;
          }
        }
        @media (max-width: 991px) {
          padding: 5vw;
        }
      }
      &.earnColCards {
        & > div {
          width: 60%;
        }

        @media (max-width: 991px) {
          padding: 0;
          & > div {
            width: 100%;
            .earnSlider {
              &.slides-container {
                height: 25vh;
              }
              .slide {
                width: calc(60%);
                overflow: hidden;
                // padding-left: 2em;
                // padding-right: 2em;
                height: 100%;
                padding: 0 5vw;

                padding-bottom: 1vw;

                .cardStyle {
                  height: 100%;
                }
              }
            }
          }
        }
      }
      &.earnColTitle {
        @media (max-width: 991px) {
          padding: 0 5vw;
          text-align: center;
          & > div {
            width: 100%;
          }
        }
      }
    }
  }
}
