.rootPrivacity {
  background-color: theme-color('bgColor');
  //   padding: 50px;
}
.privacityContainer {
  padding-top: 150px;
  color: theme-color('textColor');

  text-align: left !important;

  h3 {
    text-align: left !important;
    font-family: 'Microgramma D Extended';
  }
}
.privacityContent h2,
.privacityContent h4 {
  text-align: left !important;
  color: theme-color('textColor');
}

.privacityContent h4 {
  text-align: left !important;
  margin-top: 60px;
}

@media (max-width: 700px) {
  .rootPrivacity {
    padding-right: 25px;
    padding-left: 25px;
  }
}
