.nutritionPage {
  /* BACKGROUND ROW */

  .RootTransformation {
    height: auto;
    background-color: transparent;
    padding-top: 7.5vw;
    padding-bottom: 7.5vw;
    display: flex;
    justify-content: center;
  }

  /* CONTENT */
  .RootTransformation-inside {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 90%;
  }

  @media (min-width: 1440px) {
    .RootTransformation-inside {
      max-width: 1440px;
    }
  }
  @media (max-width: 1440px) and (min-width: 992px) {
    // .RootTransformation {
    //   padding-top: 20vw;
    // }
  }

  @media (max-width: 991px) {
    .RootTransformation {
      padding-top: 20vw;
      padding-bottom: 10vw;
    }
  }
}
